import Api from './api';

export class VATApi {
    static getAll = async () => {
        const response = await Api.axios.get('vat');
        return response.data;
    };

    static update = async (id, data) => {
        const response = await Api.axios.put(`vat/${id}`, data);
        return response.data;
    };
}
