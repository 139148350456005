import Api from './api';

export class CityApi {
    static getAll = async () => {
        const response = await Api.axios.get('cities');
        return response.data;
    };
    static show = async (id) => {
        const response = await Api.axios.get(`cities/${id}`);
        return response.data;
    };

    static store = async (data) => {
        const response = await Api.axios.post('cities', data);
        return response.data;
    };

    static update = async (data, id) => {
        const response = await Api.axios.put(`cities/${id}`, data);
        return response.data;
    };

    static delete = async (id) => {
        const response = await Api.axios.delete(`cities/${id}`);
        return response.data;
    };
}
