import { DishApi } from '@/api';

export const dish = {
    namespaced: true,
    state: {
        dishes: [],
        meta: {
            pageCount: 1,
            currentPage: 1,
            totalCount: 0,
            perPage: 0,
        },
        paramsData: {
            mealType: '',
            menuType: '',
            isUnique: false,
            isEvaluated: false,
            selectedSortBy: 1,
            sortField: 'created_at',
            sortDirection: 'desc',
            page: 1,
        },
    },
    getters: {
        dishes: (state) => {
            return state.dishes;
        },
        meta: (state) => {
            return state.meta;
        },
        params: (state) => {
            return state.paramsData;
        },
    },
    mutations: {
        setDishes(state, dishes) {
            state.dishes = dishes;
        },

        setMeta(state, meta) {
            state.meta = meta;
        },

        updateParamsField(state, payload) {
            Object.entries(payload).forEach((element) => {
                state.paramsData[element[0]] = element[1];

                if (element[0] !== 'page') {
                    state.paramsData.page = 1;
                }
            });
        },

        addDish(state, dish) {
            state.dishes = [dish, ...state.dishes];
        },

        updateDishById(state, newDish) {
            let dish = state.dishes.find((item) => item.id === newDish.id);

            if (dish) {
                const index = state.dishes.indexOf(dish);
                state.dishes[index] = newDish;
            }
        },

        deleteDishById(state, dishId) {
            const dishes = state.dishes.filter((dish) => dish.id !== dishId);

            state.dishes = [...dishes];
        },
    },
    actions: {
        async getAllDishes({ commit, state }, payload = {}) {
            commit('updateParamsField', payload);

            const response = await DishApi.getAll(state.paramsData);

            const meta = {
                pageCount: response.meta.last_page,
                currentPage: response.meta.current_page,
                totalCount: response.meta.total,
                perPage: response.meta.to - response.meta.from + 1,
            };

            commit('setDishes', response.data);
            commit('setMeta', meta);
        },

        async destroyDish({ commit }, dishId) {
            await DishApi.destroy(dishId);

            commit('deleteDishById', dishId);
        },
    },
};
