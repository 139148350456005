import LocalStorage from '@/helpers/LocalStorageHelper';
import Cookies from 'js-cookie';
import { PersonalMenuApi } from '@/api';
import { IndividualOrderableEnum } from '@/utils/enums';

export const individualOrder = {
    namespaced: true,
    state: {
        order: null,
        personalMenu: null,
        allSelectedAdditionsState: null,
        progress: {
            allergens: false,
            dislikedProducts: false,
            macronutrients: false,
            dietPlans: false,
            specificDishNumber: false,
            otherRequests: false,
        },
    },
    getters: {
        orderState: (state) => {
            return state.order;
        },
        personalMenuState: (state) => {
            return state.personalMenu;
        },
        allSelectedAdditionsState: (state) => {
            return state.allSelectedAdditionsState;
        },
        progress: (state) => {
            return state.progress;
        },
    },
    mutations: {
        setIndividualOrder(state, order) {
            state.order = order;
        },
        setPersonalMenu(state, personalMenu) {
            state.personalMenu = personalMenu;
        },
        setAllSelectedAdditions(state, allSelectedAdditionsState) {
            state.allSelectedAdditionsState = allSelectedAdditionsState;
        },
        updateProgress(state, payload) {
            state.progress[payload.field] = payload.value;
        },
    },
    actions: {
        setWholeIndividualOrder({ dispatch }, payload) {
            dispatch('setIndividualOrder', payload?.order);
            dispatch('setPersonalMenu', payload?.personalMenu);
            dispatch('setAllSelectedAdditions', payload?.allSelectedAdditionsState);
        },

        setIndividualOrder({ commit }, order) {
            const individualOrderLocalStorage = LocalStorage.show('individual-order');

            if (typeof order === 'undefined') {
                if (individualOrderLocalStorage) {
                    commit('setIndividualOrder', individualOrderLocalStorage);
                    LocalStorage.create('individual-order', individualOrderLocalStorage);
                }
            } else {
                commit('setIndividualOrder', order);
                LocalStorage.create('individual-order', order);
            }
        },

        // PersonalMenu
        setPersonalMenu({ commit }, personalMenu) {
            const personalMenuLocalStorage = LocalStorage.show('personal-menu');

            if (typeof personalMenu === 'undefined') {
                if (personalMenuLocalStorage) {
                    commit('setPersonalMenu', personalMenuLocalStorage);
                    LocalStorage.create('personal-menu', personalMenuLocalStorage);
                }
            } else {
                commit('setPersonalMenu', personalMenu);
                LocalStorage.create('personal-menu', personalMenu);
            }
        },

        async storePersonalMenu({ dispatch }, formData) {
            const response = await PersonalMenuApi.store(formData);
            dispatch('setPersonalMenu', response.data);
        },

        async updatePersonalMenu({ dispatch, state }, formData) {
            const response = await PersonalMenuApi.update(state.personalMenu.id, formData);
            dispatch('setPersonalMenu', response.data);
        },

        async destroyPersonalMenu({ dispatch }, personalMenuId) {
            await PersonalMenuApi.destroy(personalMenuId);
            dispatch('setPersonalMenu', null);
        },

        async destroyPersonalMenuFile({ dispatch, state }) {
            const response = await PersonalMenuApi.destroyFile(state.personalMenu.id);

            dispatch('setPersonalMenu', response.data);
        },

        setAllSelectedAdditions({ commit }, idsData) {
            const allSelectedAdditionsStateLocalStorage = LocalStorage.show('individual-order-selected-additions');

            if (typeof idsData === 'undefined') {
                if (allSelectedAdditionsStateLocalStorage) {
                    commit('setAllSelectedAdditions', allSelectedAdditionsStateLocalStorage);
                    LocalStorage.create('individual-order-selected-additions', allSelectedAdditionsStateLocalStorage);
                }
            } else {
                commit('setAllSelectedAdditions', idsData);
                LocalStorage.create('individual-order-selected-additions', idsData);
            }
        },

        clearIndividualOrderCookies({ commit, dispatch, state }) {
            LocalStorage.delete('individual-order');
            LocalStorage.delete('personal-menu');
            LocalStorage.delete('individual-order-selected-additions');
            Cookies.remove('delivery-information');
            Cookies.remove('buyer-information');
            Cookies.remove('paymentType');

            dispatch('checkOut/removePaymentTypeCookie', null, { root: true });

            if (
                state.personalMenu &&
                state.order?.orderableItem.id !== state.personalMenu.id &&
                state.order?.orderableType !== IndividualOrderableEnum.PERSONAL_MENU
            ) {
                dispatch('destroyPersonalMenu', state.personalMenu.id);
            }

            commit('setPersonalMenu', null);
            commit('setIndividualOrder', null);
            commit('setAllSelectedAdditions', null);

            commit('orderPrices/setPrices', null, { root: true });
            commit('orderInformation/setDeliveryInformation', null, { root: true });
            commit('orderInformation/setBuyerInformation', null, { root: true });
        },
    },
};
