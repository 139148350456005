export default {
  "navbar": {
    "mealKits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meal kits"])},
    "weeksMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weeks menu"])},
    "prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prices"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
    "checkoutOrderSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkout / Order settings"])},
    "dishes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dishes"])},
    "allergens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allergens"])},
    "dislikedProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disliked products"])},
    "macronutrients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macronutrients"])},
    "dietPlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diet plan"])},
    "dishNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dish number"])},
    "otherRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other requests"])},
    "individualOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individual order"])},
    "otherSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other settings"])},
    "orderManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order management"])},
    "discounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discounts"])},
    "blogArticles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog articles"])},
    "kitOrderSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kit order settings"])},
    "adminsProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin's profile"])},
    "eu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EU Support"])},
    "cristmasEveForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Christmas set"])},
    "adminsPages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pages"])},
    "adminCities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cities"])}
  },
  "buttons": {
    "lookUpKits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Look up meal kits"])},
    "orderNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order now"])},
    "makeRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make a request"])},
    "physicalActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Physical activity"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "payCompleteOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay / Complete order"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results"])},
    "changeParameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change parameters"])},
    "moreArticles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More articles"])},
    "allQuestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All questions"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "weightMaintain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintain weight"])},
    "weightLoss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight loss"])},
    "weightGain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight gain"])},
    "addNewDish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new dish"])},
    "resetToDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset to default"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
    "updateLowercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["update"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "createNewDish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new dish"])},
    "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show"])},
    "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide"])},
    "addNewKit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new kit"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "addImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add image"])},
    "makeThumbnail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make thumbnail"])},
    "addOrderTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add order time"])},
    "addNewItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Add new item"])},
    "addNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new"])},
    "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])},
    "evaluateDish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluate the dish"])},
    "showAllergens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show allergens"])},
    "showCalorieNorm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show calorie norm"])},
    "addDeliveryTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add delivery time"])},
    "addFaqItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add FAQ Item"])},
    "resetOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset options"])},
    "addToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to cart"])},
    "makeAnOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make an order"])},
    "updateAndMakeAnOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update and make an order"])},
    "addCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add code"])},
    "continueBuying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to the order"])},
    "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkout"])},
    "setAccountInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set account information"])},
    "orderManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order management"])},
    "goBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go back"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])},
    "addFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add file"])},
    "changeFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change file"])},
    "orderDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order details"])},
    "savePriceApproveOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save price / Approve order"])},
    "cantBeProcessed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can't be processed"])},
    "makePayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make a payment"])},
    "approvePayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve payment"])},
    "paymentNotMade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment not made"])},
    "saveComments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save comments"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
    "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approve"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice"])},
    "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
    "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])},
    "notPaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not paid"])},
    "saveNewAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save new address"])},
    "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
    "signUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
    "restorePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restore password"])},
    "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
    "newBlogPost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New blog post"])},
    "iAgree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree"])},
    "viewCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View cart"])},
    "newItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New item"])},
    "usePoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use points"])},
    "resetPoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset points"])},
    "pointsApplied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points applied"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "continueOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue order"])},
    "cancelOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel order"])},
    "reorder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reorder"])},
    "confirmReturnedAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm returned amount"])},
    "regularOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regular orders"])},
    "individualOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individual orders"])},
    "changeDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change details"])},
    "changeOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change order"])},
    "moreSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More settings"])},
    "newPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New page"])},
    "payWithoutRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay without registration"])},
    "registerOrLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register or Login"])},
    "newCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New city"])}
  },
  "weekDays": {
    "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday"])},
    "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuesday"])},
    "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wednesday"])},
    "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thursday"])},
    "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friday"])},
    "mon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon"])},
    "tue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tue"])},
    "wed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wed"])},
    "thu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thu"])},
    "fri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fri"])},
    "sat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sat"])},
    "sun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sun"])}
  },
  "common": {
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deleted"])},
    "kgWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kg/week"])},
    "kcalDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kcal/day"])},
    "kcal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kcal"])},
    "meal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["meal"])},
    "meals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["meals"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
    "forADay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for a day"])},
    "havePescatarian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have pescatarian"])},
    "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
    "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
    "requiredFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Required fields"])},
    "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["years"])},
    "cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cm"])},
    "kg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kg"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surname"])},
    "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "ageFrom": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Age (from ", _interpolate(_named("MIN_AGE")), " to ", _interpolate(_named("MAX_AGE")), ")"])},
    "heightCm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Height (cm)"])},
    "weightKg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight (kg)"])},
    "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
    "sortBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort by"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
    "breakfast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breakfast"])},
    "lunch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunch"])},
    "dinner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dinner"])},
    "snack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snack"])},
    "snack_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snack 1"])},
    "snack_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snack 2"])},
    "undefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undefined"])},
    "newestCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newest created first"])},
    "oldestCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oldest created first"])},
    "highestEvaluated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Highest evaluated first"])},
    "lowestEvaluated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lowest evaluated first"])},
    "mealUndefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meal undefined"])},
    "allergens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allergens"])},
    "calorieNorm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calorie norm (kcal)"])},
    "carbohydrates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carbohydrates (g)"])},
    "fats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fats (g)"])},
    "proteins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proteins (g)"])},
    "votes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("votes")), " votes"])},
    "dishName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dish name"])},
    "dishDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dish description"])},
    "regularMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regular menu"])},
    "uniqueMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unique menu"])},
    "areYouSureDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete"])},
    "regular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regular"])},
    "vegetarian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pescatarian"])},
    "pescatarian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pescatarian"])},
    "noItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No items"])},
    "noAllergensDefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No allergens defined"])},
    "extendAllSections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extend all sections"])},
    "kitName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kit name"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price (€)"])},
    "combinationName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combination name"])},
    "priceForDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price for a day (€)"])},
    "thumbnail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbnail"])},
    "lastOrderDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last order day"])},
    "deliveryDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery date"])},
    "orderTimeFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order time (from)"])},
    "orderTimeTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order time (to)"])},
    "menuName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu name"])},
    "macronutrients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macronutrients"])},
    "mealNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meal number"])},
    "daysAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days amount"])},
    "rateDish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate the dish"])},
    "andNutrients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(and nutrients)"])},
    "deliveryMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery method"])},
    "deliveryTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery time"])},
    "deliveryTimeFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery time (from)"])},
    "deliveryTimeTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery time (to)"])},
    "addPercentageToIncreaseKitPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add percentage to increase kit price"])},
    "additions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additions"])},
    "deliveryMethodsOnOff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery methods (on/off)*"])},
    "deliveryPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery price"])},
    "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT"])},
    "deliveryTimeSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery time settings"])},
    "deliveryDateSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery date settings"])},
    "paymentsOnOff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments (on/off)*"])},
    "montonio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montonio"])},
    "bankTransfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank transfer"])},
    "cashPaymentToCourier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash (payment to courier)"])},
    "cash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash"])},
    "timerSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timer settings*"])},
    "disabledHideTimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable/hide timer"])},
    "generalSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General settings"])},
    "regularKitsSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regular kits settings"])},
    "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])},
    "discountCodesAndPoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Discount codes and points)"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal"])},
    "withoutAllergen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Without ", _interpolate(_named("allergen"))])},
    "pleaseWaitAccountCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait while the account is being created (logged)"])},
    "yourOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your order"])},
    "vatAmount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(Accounted for €", _interpolate(_named("vat")), " VAT)"])},
    "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free"])},
    "freeDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free delivery"])},
    "discountApplied": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), _interpolate(_named("sign")), " Code discount applied"])},
    "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["here"])},
    "discountCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount code"])},
    "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery"])},
    "forUniqueKits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(for unique kits)"])},
    "pricesShownForDays": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prices shown for ", _interpolate(_named("amount")), " day(s)"])},
    "phoneNr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone nr."])},
    "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an account"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street"])},
    "houseNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["House number"])},
    "house_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["House number"])},
    "apartmentNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apartment number"])},
    "apartment_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apartment number"])},
    "floor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Floor"])},
    "postalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal code"])},
    "postal_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal code"])},
    "extraInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra information"])},
    "descriptionExtraInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description / extra information"])},
    "extra_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra information"])},
    "buyerInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buyer info"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])},
    "iAgreeWith": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree with"])},
    "orderPlaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order placed!"])},
    "pricesWasUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prices was updated!"])},
    "timerUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timer updated!"])},
    "paymentFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment filter"])},
    "orderStatusFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order status filter"])},
    "orderPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order price (EUR)"])},
    "orderedKitComments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordered kit comments"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "paymentProcessing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment processing"])},
    "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy policy"])},
    "termsAndConditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and conditions"])},
    "termsAndConditionsLowest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["terms and conditions"])},
    "404_pageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately the page you are looking for does not exist. Follow one of the links bellow to find what you are looking for"])},
    "currentPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current password"])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
    "confirmNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm New Password"])},
    "outOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["out of"])},
    "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results"])},
    "cantCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can't copy"])},
    "linkCopied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link copied!"])},
    "kitsTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kits total"])},
    "lookUpPostalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Look up postal code"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surname"])},
    "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company name"])},
    "restore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restore"])},
    "iAgreeWithContacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree, my specified contacts,receive information on the progress of my order"])},
    "iAgreeWithDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I agree, my specified contacts, receive discount offers tailored to me"])},
    "business_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business name"])},
    "business_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company code"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "checkout_without_registration_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After registration, you will be able to: See and manage your order You will know exactly when the last day of your order is You will accumulate loyalty points P.S. \nIf you place an order without registration, you will not see it in your account later"])},
    "client_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer information"])},
    "delivery_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery information"])},
    "business_workers_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of employees"])},
    "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telephone number"])}
  },
  "errors": {
    "fieldIsRequired": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("key")), " field is required"])},
    "minMaxAge": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The ", _interpolate(_named("key")), " should be from ", _interpolate(_named("MIN_AGE")), " to ", _interpolate(_named("MAX_AGE"))])},
    "incorrectImageType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect file type! Please upload another photo"])},
    "incorrectFileType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect file type! Please upload another file"])},
    "discountCodeAlreadyApplied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount code already applied"])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required"])},
    "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["More than ", _interpolate(_named("amount")), " characters are required if filling this field"])},
    "maxLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This field must not be grater than ", _interpolate(_named("amount")), " characters"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address is not valid"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid phone number format. Number should start with +3706"])},
    "someFieldsAreNotFilledCorrectly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some fields are not filled correctly"])},
    "i_agreen_with_term_of_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must agree to the terms of use."])},
    "uniqueMealKitRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This mealkit can not use discount code. "])}
  },
  "order": {
    "kit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kit"])},
    "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery"])},
    "buyerInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buyer info"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "extraAdditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra additions"])},
    "typeSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What type of kit do you want?"])},
    "kitSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What kit are you interested in?"])},
    "personalMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal menu"])},
    "personalMenuDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When selecting “Personal menu” you have to add menu content through one of the methods bellow and click “Save” in order to continue"])},
    "otherCalorieAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other calorie amount"])},
    "orderTerm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Term"])},
    "numberOfKits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of kits"])},
    "individualRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individual requests"])},
    "cantFindInList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can't find in list?"])},
    "macronutrientTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Input daily macronutrient amount that you want to get or add comments for more detailed requirements"])},
    "proteinsGram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proteins (g)"])},
    "fatsGram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fats (g)"])},
    "carbohydratesGram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carbohydrates (g)"])},
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments"])},
    "otherDietPlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other diet plan"])},
    "specificDishNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specific dish number"])},
    "otherRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other requests"])},
    "resetAllSelections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset all selections"])},
    "clearSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Clear selection"])},
    "individualOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individual order"])},
    "deliveryDateHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery date can not be earlier than 3 days from current day"])},
    "deliveryInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery information"])},
    "orderPaidSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order paid successfully"])},
    "orderRequestPlaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order request placed!"])},
    "orderPriceHasChanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order price has changed"])},
    "orderPriceHasChangedDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We want to inform that kit (or one of the kits) price has changed. Price in your order has been updated"])}
  },
  "placeholder": {
    "enterName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter name"])},
    "enterYourName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your name"])},
    "enterSurname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your surname"])},
    "enterCompanyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter company name"])},
    "enterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email"])},
    "enterMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your message"])},
    "enterAge": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Enter your age (from ", _interpolate(_named("MIN_AGE")), " to ", _interpolate(_named("MAX_AGE")), ")"])},
    "enterHeight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your height (cm)"])},
    "enterWeight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your weight (kg)"])},
    "enterDishName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter dish name"])},
    "enterDishDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter dish description"])},
    "enterCalorie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter calorie norm (kcal)"])},
    "enterCarbohydrates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter carbohydrates (g)"])},
    "enterFats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter fats (g)"])},
    "enterProteins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter proteins (g)"])},
    "chooseDish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a dish"])},
    "enterKitName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Kit name"])},
    "enterKitDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Kit description"])},
    "enterPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Price (€)"])},
    "enterCombinationName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter combination name"])},
    "enterPriceForDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter price for a day (€)"])},
    "enterMenuName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter menu name"])},
    "enterAmountOfDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter amount of days"])},
    "increaseInPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Increase in price"])},
    "deliveryPriceWorkingDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery price - working day (EUR)"])},
    "deliveryPriceWeekendDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery price - weekend/holiday (EUR)"])},
    "enterDeliveryPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter delivery price"])},
    "vatForKits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT for kits (%)"])},
    "vatForDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT for delivery (%)"])},
    "timerStartTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timer start time"])},
    "timerEndTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timer end time"])},
    "enterDiscountCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter discount code"])},
    "enterPhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter phone number"])},
    "enterInformationToCourier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter information to courier"])},
    "enterStreet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter street"])},
    "enterApartmentNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter apartment number"])},
    "enterFloor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter floor"])},
    "enterPostalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter postal code"])},
    "enterExtraInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter extra information"])},
    "enterOrderPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Order price (EUR)"])},
    "enterOrderedKitComments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Ordered kit comments"])},
    "enterEmailAddressOrUserName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter email address or user name"])},
    "enterEmailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter email address"])},
    "enterPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter password"])},
    "enterNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter new password"])},
    "enterCurrentPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter current password"])},
    "enterConfirmNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm new password"])},
    "uniqueKitDeliveryPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unique Kit delivery price"])},
    "regularKitDeliveryPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regular Kit delivery price"])},
    "enterArticleName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter article name"])},
    "selectYourCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select your city"])},
    "sectionName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Section name"])},
    "enterPromotionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter promotion text"])},
    "enterPurchaseDiscountPoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter purchase discount points (for 1 Eur)"])},
    "enterMaxDiscountPointUsage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter max discount point usage (%)"])},
    "enterReferralDiscountPoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter referral discount points"])},
    "enterReferralReceiverDiscountPoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter referral receiver discount points"])},
    "enterPageName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a page title"])},
    "enterDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the date"])},
    "enterSlugName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the page address (optional)"])},
    "enterBusinessName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your company name"])},
    "enterBusinessCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your company code"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email \ne-mail address, First name, Last name"])},
    "enterBusinessWorkersCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the number of employees"])},
    "enterPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a phone number"])},
    "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuolaidos kodas"])},
    "enterCityPreOrderMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your pre-order message"])},
    "enterCityName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the name of the city"])}
  },
  "main": {
    "previewSection": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Healthy meal kits for all day at your doorstep"])}
    },
    "timerSection": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Be sure to order meal kit for"])},
      "day": {
        "Tomorrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tomorrow"])},
        "Monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday"])},
        "Tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuesday"])},
        "Wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wednesday"])},
        "Thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thursday"])},
        "Friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friday"])}
      }
    },
    "howItWorksSection": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How it works"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working principle of the system"])},
      "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose daily meal plan or make your own"])},
      "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We'll make fresh meals ready to eat"])},
      "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kits will get delivered to you every working day"])}
    },
    "mealKitsSection": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meal Kits"])}
    },
    "calorieSection": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calorie calculator"])},
      "parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameters"])},
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
      "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results"])},
      "generalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General information"])},
      "physicalActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Physical activity"])},
      "exercise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exercise"])},
      "exerciseDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 15-30 min of elevated heart rate activity"])},
      "intenseExercise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intense exercise"])},
      "intenseExerciseDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 45-120 min of elevated heart rate activity"])},
      "veryIntenseExercise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Very intense exercise"])},
      "veryIntenseExerciseDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- +2 hours of elevated heart rate activity"])},
      "chooseSuitOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the option that best suit your needs"])},
      "mildWeightLoss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mild weight loss"])},
      "weightLoss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight loss"])},
      "extremeWeightLoss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extreme weight loss"])},
      "mildWeightGain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mild weight gain"])},
      "weightGain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weight gain"])},
      "extremeWeightGain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extreme weight gain"])},
      "sedentary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sedentary"])},
      "sedentaryDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- little or no exercise"])},
      "light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Light"])},
      "lightDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- exercise 1-3 times a week"])},
      "moderate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moderate"])},
      "moderateDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- exercise 4-5 times a week"])},
      "veryActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Very active"])},
      "veryActiveDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- intense exercise 6-7 times a week"])},
      "extraActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra active"])},
      "extraActiveDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- very intense exercise daily or physical work"])},
      "calorieConsumption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calorie consumption"])},
      "basedOnCalories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["based on calories"])},
      "extremeWeightLossInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please consult with a doctor when losing 1 kg or more per week since it requires that you consume less than the minimum recommendation of 1, 500 calories a day."])}
    },
    "blogSection": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog"])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Healthy eating tips, information on nutrition plans"])}
    },
    "faqSection": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
      "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What we get asked (Frequently Asked Questions)"])},
      "faqQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question"])},
      "faqAnswer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer"])},
      "newSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New section"])},
      "noItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No items added"])},
      "deleteSection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete section"])},
      "changeSectionName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change section name"])},
      "section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["section"])},
      "element": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["element"])},
      "cantFindAnswers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can’t find answers? Get in touch"])}
    },
    "getInTouch": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get in touch"])},
      "modalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your feedback!"])},
      "modalMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your message has been successfully delivered"])}
    }
  },
  "user": {
    "personalSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal settings"])},
    "kitManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kit management"])},
    "orderManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order management"])},
    "discounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discounts"])},
    "calorieCalculator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calorie calculator"])},
    "notificationSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification settings"])},
    "logInSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in settings"])},
    "updatePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update password"])},
    "personalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal information"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "realName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real name"])},
    "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surname"])},
    "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company name"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
    "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
    "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone nr."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "passwordMissMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password and password confirmation don't match"])},
    "successTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success!"])},
    "personalInformationChangedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal information changed successfully!"])},
    "passwordChangedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password changed successfully!"])},
    "addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My addresses"])},
    "createNewAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create new address"])},
    "addressName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address name"])}
  },
  "dishes": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dishes"])},
    "dish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dish"])},
    "editDish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit dish"])},
    "newDish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New dish"])},
    "hideNotEvaluatedDishes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide not evaluated dishes"])},
    "uniqueMenuDish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unique menu dish"])},
    "calories": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["kcal - ", _interpolate(_named("amount"))])},
    "carbohydrates": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Carbohydrates - ", _interpolate(_named("amount")), " g"])},
    "fats": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fats - ", _interpolate(_named("amount")), " g"])},
    "proteins": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Proteins - ", _interpolate(_named("amount")), " g"])},
    "comment": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Comment - ", _interpolate(_named("amount"))])},
    "caloriesNotSpecified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kcal not specified"])},
    "proteinsNotSpecified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proteins not specified"])},
    "fatsNotSpecified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fats not specified"])},
    "carbohydratesNotSpecified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carbohydrates not specified"])},
    "evaluationStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dish evaluation status"])},
    "noDishesAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No dishes added"])},
    "dishNotSpecified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dish not specified"])},
    "caloriesAndNutrients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calories and nutrients"])}
  },
  "menus": {
    "weeksMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weeks menu"])},
    "menuWillSwitched": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The menu will be switched on Saturday 09:00AM"])},
    "nextWeeksMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next weeks menu"])},
    "currentWeeksMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current weeks menu"])},
    "uniqueMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])}
  },
  "mealKits": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meal kits"])},
    "regularKits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regular kits"])},
    "uniqueKits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unique kits"])},
    "regularKit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regular kit"])},
    "uniqueKit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unique kit"])},
    "noKitsAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Meal Kits added"])},
    "kit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kit"])},
    "editKit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Meal Kit"])},
    "addNewKit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new Meal Kit"])},
    "selectMenus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Menus*"])},
    "selectPeriods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Periods*"])},
    "selectMeals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Meals*"])},
    "combinations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combinations*"])},
    "selectMinimumMeals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select minimum 1 meal"])},
    "noCombinations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no suitable combinations"])},
    "createCombination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Combination"])},
    "calorieNorms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calorie norms*"])},
    "imageGallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image gallery"])},
    "noImagesAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No images added to the gallery"])},
    "orderDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order details*"])},
    "applyDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply discount"])},
    "uniqueMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unique menu*"])},
    "noItemsAddedToMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No items added to the menu"])},
    "showHideAfterCreating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show/hide after creating"])},
    "recommendedKits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended kits"])},
    "customMealSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom meal selection"])},
    "chooseMealCombination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose meal combination"])},
    "thereAreNoRegularMealKits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no Regular Meal Kits"])},
    "thereAreNoMealKits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no Meal Kits"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "macronutrients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macronutrients"])},
    "kcalPricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kcal pricing*"])},
    "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter"])},
    "dayDaysPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["day (days price) (€)"])},
    "averageDailyMacronutrients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average daily macronutrients"])},
    "chooseCalorieSizeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose calorie size:"])},
    "comparisonOfAvailableCalorieChoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparison of available calorie choices and average daily amounts for macronutrients"])}
  },
  "individualOrder": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individual order"])},
    "hideSections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide sections/selections"])},
    "personalKit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal kit"])},
    "dislikedProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disliked products"])},
    "dietPlans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diet plans"])},
    "orderPeriods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order periods"])},
    "otherRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other requests"])},
    "allergens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allergens"])},
    "macronutrients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Macronutrients"])},
    "dishNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dish number"])},
    "caloriesAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calories amount"])},
    "orderDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order duration"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "submitHelpText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before submitting, check if you provided correct information"])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
    "submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your order has been submitted successfully."])},
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])}
  },
  "periods": {
    "createPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create period"])}
  },
  "deliveryMethods": {
    "to_hands": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To hands"])},
    "leave_at_door": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave at the door"])},
    "take_outside": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take outside"])}
  },
  "paymentMethods": {
    "montonio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montonio"])},
    "bank_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank transfer"])},
    "cash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash (payment to courier)"])},
    "disablePaymentMethodsModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to disable this payment?"])},
    "montonioDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montonio - quickest and most convenient payment solution, possibility to pay with payment card"])}
  },
  "earliestDeliveryDay": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Earliest delivery day"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next day"])},
    "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Second day"])},
    "third": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third day"])},
    "setDeliveryDatesManually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set delivery dates (delivery availability on/off) manually"])}
  },
  "cart": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cart"])},
    "kitType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kit type"])},
    "mealSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meal selection"])},
    "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Term"])},
    "allergensTooltipText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By selecting an allergen from the allergen list below, your menu may differ from the general weekly menu. Percentages reflect price increase"])},
    "forWorkingDays": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For ", _interpolate(_named("amount")), " working day(s)"])},
    "forDays": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For ", _interpolate(_named("amount")), " day(s)"])},
    "numberOfKits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of kits"])},
    "forADay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For a day"])},
    "otherAllergen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Other allergen (type in) (+", _interpolate(_named("percentage")), "%)"])},
    "withoutAllergen": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Without ", _interpolate(_named("name")), " (+", _interpolate(_named("percentage")), "%)"])},
    "cartIsEmpty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cart is empty"])},
    "readAboutDiscountCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read more about discount code"])},
    "noItemsInCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No items in the cart"])},
    "goToMealKitsSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to meal kits selection to add an item"])},
    "deliveryForRegularKits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(for regular kits)"])},
    "periodsTooltipText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you select an order period of less than 20 working days, the nutrition kit will be delivered in a paper bag"])}
  },
  "buyerInfo": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buyer information"])},
    "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
    "deliveryAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery address"])},
    "youCanSaveAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can save your address to be used another time"])},
    "editAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Address"])}
  },
  "bankAccount": {
    "yourOrderNumberIs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your order number is"])},
    "whenMakingBankTransfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When making a bank transfer add order number."])},
    "whenWeReceivePayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When we receive payment order will be processed."])},
    "paymentInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment information"])},
    "accountIsHeldAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account is held at"])},
    "payseraAccountOwner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paysera Account owner"])},
    "accountNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account number"])},
    "amountToBePaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount to be paid"])}
  },
  "cashModal": {
    "youCanCheckStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can check your order status in"])},
    "ofUserPanel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of your user panel"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Be sure to have exact amount to be paid to courier, because he might not have change"])}
  },
  "deliveryInfo": {
    "informationToCourier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information to courier"])}
  },
  "otherSettings": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other settings"])},
    "orderPeriods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order periods"])},
    "createOrderPeriods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create order periods"])},
    "orderPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order period (amount in days)"])},
    "allergens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allergens"])},
    "createAllergens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create allergens"])},
    "createAllergen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create allergen"])},
    "allergenName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allergen name"])},
    "typeDish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dish"])},
    "typeOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])},
    "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" was created!"])},
    "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" was deleted!"])},
    "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" was updated!"])},
    "kitAdditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kit additions"])},
    "createAdditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create additions"])},
    "createAddition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create addition"])},
    "additionName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addition name"])},
    "additionPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addition price"])},
    "priceForADay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price for a day (€)"])},
    "addThumbnail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add thumbnail"])},
    "dietPlans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diet plans"])},
    "createDietPlans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create diet plans"])},
    "dietPlanName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diet plan name"])},
    "dislikedProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disliked products"])},
    "createDislikedProducts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create disliked products"])},
    "dislikedProductName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disliked product name"])},
    "combinations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Combinations"])},
    "createCombinations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create combinations"])},
    "promotionalCardInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotional card information"])},
    "promotionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotion text"])},
    "deliveryDayBefore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery day before"])},
    "deliveryDayBeforeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(delivery day before)"])},
    "hideDishEvaluationDisplay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide dish evaluation display"])},
    "hideEvaluationDisplayForUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide evaluation display for users"])},
    "evaluationDisplay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluation display"])}
  },
  "orderManagement": {
    "orderNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order number"])},
    "orderStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order status"])},
    "orderDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order date"])},
    "numberOfKits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of kits"])},
    "kitCanceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kit canceled"])},
    "kitsCanceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kit(s) canceled"])},
    "byBuyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(by buyer)"])},
    "byProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(by provider)"])},
    "cash_payment_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment to courier. Make sure to have exact paid amount, courier might not have change"])},
    "bank_account_payment_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment through bank transfer. When you make a payment order should start being processed"])},
    "through_cash_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment to courier"])},
    "through_montonio_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment through montonio"])},
    "through_bank_account_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Payment through bank transfer"])},
    "orderDeclined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order declined"])},
    "orderCanceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order canceled due to non payment"])},
    "responseTakesUp24h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response takes up to 24h, you will get email as well"])},
    "pleaseMakePaymentWithin6h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please make a payment within 6h to get order prepared (otherwise order will be canceled)"])},
    "timeOfOrderApproval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time of order approval"])},
    "deliveryStartDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery start date"])},
    "deliveryEndDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery end date"])},
    "deliveryTimeInHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery time (in hours)"])},
    "buyersName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buyers name"])},
    "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment method"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
    "kitAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kit amount"])},
    "orderPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order price"])},
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
    "noOrdersYet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No orders yet"])},
    "statuses": {
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order placed"])},
      "order_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order request"])},
      "order_approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order approved"])},
      "order_approved_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(awaiting payment)"])},
      "declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canceled"])},
      "waiting_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for payment"])},
      "waiting_payment_cash_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(payment to courier)"])},
      "waiting_payment_bank_account_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(payment approval from admin)"])},
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully paid"])},
      "not_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment not made"])},
      "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order completed"])}
    },
    "cantProcessedModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure order can not be processed?"])},
    "savePriceModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to save this price for the order?"])},
    "notPaidModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure the order is not paid for and you want to cancel it?"])},
    "paidModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure the order is paid for and you want to approve it?"])},
    "completedModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure order is completed?"])},
    "kitEvents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kit events"])},
    "kitCancellationRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kit cancellation request"])},
    "discountCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount code"])},
    "userInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The client"])}
  },
  "discounts": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discounts"])},
    "createLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create discount codes"])},
    "expirationHelpText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount codes expire after 2 months (60 days) from creation"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount code"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount amount (%)"])},
    "amountFixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount amount (€)"])},
    "multiple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple discount codes"])},
    "multipleInSingleOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple code usage in single order"])},
    "mealKits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select meal kits for which the discount will be applied"])},
    "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percentage"])},
    "fixed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed"])},
    "discountCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount created!"])},
    "discountDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount deleted!"])},
    "createDiscounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create discounts"])},
    "pointSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Point settings"])},
    "discountPointsExpireAfter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Discount points expire after ", _interpolate(_named("days")), " days from first point batch collection"])},
    "purchaseDiscountPoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase discount points (for 1 Eur)"])},
    "pointsBuyerEarnsPerEur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points buyer earns per 1 Eur when he purchases kits"])},
    "maxDiscountPointUsage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max discount point usage (%)"])},
    "maximumAmountOfOrdersValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum amount of orders value that user can use discount points for"])},
    "disableDiscountPointCollecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable discount point collecting"])},
    "disableDiscountPointUsage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable discount point usage"])},
    "discountPointSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount point settings"])},
    "referralSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referral settings"])},
    "referralDiscountPoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referral discount points"])},
    "howManyPoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How many points user receives for successful referral"])},
    "referralReceiverDiscountPoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referral receiver discount points"])},
    "discountPointsPersonWhoUsesLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount points a person who uses link should receive"])},
    "referralFunctionDisable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referral function disable"])},
    "discountPointValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount point value (Eur per 1 point)"])},
    "howMuchValueDoesPointHold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How much value does 1 point hold when used in purchase (value in Eur)"])},
    "citiesIds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the city(s) for which the discount code will be valid."])}
  },
  "contacts": {
    "companyRequisites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company requisites"])},
    "companyCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company code"])},
    "companyAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company address"])},
    "socialInsuranceCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social insurance code"])},
    "kitDeliveriesAvailableIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kit deliveries available in"])},
    "followUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow us"])}
  },
  "footer": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Meal kits for your convenience! Deliveries available in Vilnius, Kaunas and Klaipeda"])},
    "usefulInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Useful information"])},
    "mainMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main menu"])},
    "solution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution"])},
    "allRightsAreReserved": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["©️ Cityrush ", _interpolate(_named("year")), " - all rights are reserved"])}
  },
  "login": {
    "emailAddressOrUserName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address or user name"])},
    "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password?"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "rememberMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember me"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])}
  },
  "register": {
    "emailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
    "passwordWillBeSentToEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password for your account will be sent to this email"])},
    "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your personal information will be used only for orders. For more information you can look at our"])},
    "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["privacy policy"])}
  },
  "forgotPassword": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password?"])},
    "weWillSendEmailRestorePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will send you an email to restore your password"])},
    "emailSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email sent"])},
    "checkYourEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your email to restore your password"])},
    "passwordChanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password changed"])},
    "yourPasswordSuccessfullyChanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password successfully changed"])}
  },
  "blogArticles": {
    "blogPosts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog posts"])},
    "blogPost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["blog post"])},
    "noBlogArticlesAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Blog Articles added"])},
    "articleName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article name"])},
    "post": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post"])},
    "pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pictures"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content"])},
    "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog"])},
    "blogLinkCopied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog link copied!"])}
  },
  "policies": {
    "cookieConsentText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are using cookies that help you comfortably use our website with all of its main features. If you want to know more, read our"])},
    "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])}
  },
  "termsAndConditions": {
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OVERVIEW"])},
    "paragraph1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This website is operated by UAB Londesa company. Throughout the site, the terms “we”, “us” and “our” refer to UAB Londesa company. UAB Londesa company offers this website, including all information, tools and Services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here."])},
    "paragraph2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By visiting our site and/ or purchasing something from us, you engage in our “Service” and agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/ or contributors of content. Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any Services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service."])},
    "paragraph3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any new features or tools which are added to the current store shall also be subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes."])},
    "section1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECTION 1 - ONLINE STORE TERMS"])},
    "paragraph4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site."])},
    "paragraph5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You may not use our products for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws)."])},
    "paragraph6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must not transmit any worms or viruses or any code of a destructive nature."])},
    "paragraph7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A breach or violation of any of the Terms will result in an immediate termination of your Services."])},
    "section2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECTION 2 - GENERAL CONDITIONS"])},
    "paragraph8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We reserve the right to refuse service to anyone for any reason at any time."])},
    "paragraph9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You understand that your content (not including credit card information), may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks."])},
    "paragraph10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website through which the service is provided, without express written permission by us."])},
    "paragraph11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms."])},
    "section3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECTION 3 - ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION"])},
    "paragraph12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are not responsible if information made available on this site is not accurate, complete or current. The material on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information. Any reliance on the material on this site is at your own risk."])},
    "paragraph13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This site may contain certain historical information. Historical information, necessarily, is not current and is provided for your reference only. We reserve the right to modify the contents of this site at any time, but we have no obligation to update any information on our site. You agree that it is your responsibility to monitor changes to our site."])},
    "section4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECTION 4 - MODIFICATIONS TO THE SERVICE AND PRICES"])},
    "paragraph14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prices for our products are subject to change without notice."])},
    "paragraph15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time."])},
    "paragraph16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We shall not be liable to you or to any third-party for any modification, price change, suspension or discontinuance of the Service."])},
    "section5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECTION 5 - PRODUCTS OR SERVICES (if applicable)"])},
    "paragraph17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have made every effort to display as accurately as possible the colors and images of our products that appear at the store. We cannot guarantee that your computer monitor's display of any color will be accurate."])},
    "paragraph18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We reserve the right, but are not obligated, to limit the sales of our products or Services to any person, geographic region or jurisdiction. We may exercise this right on a case-by-case basis. We reserve the right to limit the quantities of any products or Services that we offer. All descriptions of products or product pricing are subject to change at anytime without notice, at the sole discretion of us. We reserve the right to discontinue any product at any time. Any offer for any product or service made on this site is void where prohibited."])},
    "paragraph19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We do not warrant that the quality of any products, Services, information, or other material purchased or obtained by you will meet your expectations, or that any errors in the Service will be corrected."])},
    "section6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECTION 6 - ACCURACY OF BILLING AND ACCOUNT INFORMATION"])},
    "paragraph20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address. In the event that we make a change to or cancel an order,we may attempt to notify you by contacting the e-mail and/or billing address/phone number provided at the time the order was made. We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers or distributors."])},
    "paragraph21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You agree to provide current, complete and accurate purchase and account information for all purchases made at our store. You agree to promptly update your account and other information, including your email address so that we can contact you as needed."])},
    "section7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECTION 7 - OPTIONAL TOOLS"])},
    "paragraph22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We may provide you with access to third-party tools over which we neither monitor nor have any control nor input."])},
    "paragraph23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You acknowledge and agree that we provide access to such tools ”as is” and “as available” without any warranties, representations or conditions of any kind and without any endorsement. We shall have no liability whatsoever arising from or relating to your use of optional third-party tools."])},
    "paragraph24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any use by you of the optional tools offered through the site is entirely at your own risk and discretion and you should ensure that you are familiar with and approve of the terms on which tools are provided by the relevant third-party provider(s)."])},
    "paragraph25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We may also, in the future, offer new Services and/or features through the website (including, the release of new tools and resources). Such new features and/or Services shall also be subject to these Terms of Service."])},
    "section8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECTION 8 - PERSONAL INFORMATION"])},
    "paragraph26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your submission of personal information through the store is governed by our Privacy Policy. To view our Privacy Policy, please see Privacy Policy page/file."])},
    "section9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECTION 9 - ERRORS, INACCURACIES AND OMISSIONS"])},
    "paragraph27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Occasionally there may be information on our site or in the Service that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers, product shipping charges, transit times and availability. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information in the Service or on any related website is inaccurate at any time without prior notice (including after you have submitted your order)."])},
    "paragraph28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We undertake no obligation to update, amend or clarify information in the Service or on any related website, including without limitation, pricing information, except as required by law. No specified update or refresh date applied in the Service or on any related website, should be taken to indicate that all information in the Service or on any related website has been modified or updated."])},
    "section10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECTION 10 - PROHIBITED USES"])},
    "paragraph29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from using the site or its content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) tosubmit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses."])},
    "section11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECTION 11 - DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY"])},
    "paragraph30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We do not guarantee, represent or warrant that your use of our service will be uninterrupted, timely, secure or error-free."])},
    "paragraph31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We do not warrant that the results that may be obtained from the use of the service will be accurate or reliable."])},
    "paragraph32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You agree that from time to time we may remove the service for indefinite periods of time or cancel the service at any time, without notice to you. You expressly agree that your use of, or inability to use, the service is at your sole risk. The service and all products and Services delivered to you through the service are (except as expressly stated by us) provided 'as is' and 'as available' for your use, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non- infringement. In no case shall UAB Londesa company, our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, service providers or licensors be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from your use of any of the service or any products procured using the service, or for any other claim related in any way to your use of the service or any product, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the service or any content (or product) posted, transmitted, or otherwise made available via the service, even if advised of their possibility. Because some jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such jurisdictions, our liability shall be limited to the maximum extent permitted by law."])},
    "section12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECTION 12 - INDEMNIFICATION"])},
    "paragraph33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You agree to indemnify, defend and hold harmless UAB Londesa company and our parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third-party due to or arising out of your breach of these Terms of Service or the documents they incorporate by reference, or your violation of any law or the rights of a third-party."])},
    "section13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12. Rules for using discount codes"])},
    "paragraph34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the event that any provision of these Terms of Service is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted byapplicable law, and the unenforceable portion shall be deemed to be severed from these Terms of Service, such determination shall not affect the validity and enforceability of any other remaining provisions."])},
    "section14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECTION 14 - TERMINATION"])},
    "paragraph35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes."])},
    "paragraph36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These Terms of Service are effective unless and until terminated by either you or us. You may terminate these Terms of Service at any time by notifying us that you no longer wish to use our Services, or when you cease using our site. If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms of Service, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access to our Services (or any part thereof)."])},
    "section15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECTION 15 - ENTIRE AGREEMENT"])},
    "paragraph37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The failure of us to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right or provision."])},
    "paragraph38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These Terms of Service and any policies or operating rules posted by us on this site or in respect to The Service constitutes the entire agreement and understanding between you and us and govern your use of the Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Service). Any ambiguities in the interpretation of these Terms of Service shall not be construed against the drafting party."])},
    "section16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECTION 16 - GOVERNING LAW"])},
    "paragraph39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the laws of Lithuania."])},
    "section17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECTION 17 - CHANGES TO TERMS OF SERVICE"])},
    "paragraph40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can review the most current version of the Terms of Service at any time at this page."])},
    "paragraph41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Service by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the posting of any changes to these Terms of Service constitutes acceptance of those changes."])},
    "section18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SECTION 18 - CONTACT INFORMATION"])},
    "paragraph42": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Questions about the Terms of Service should be sent to us at ", _interpolate(_named("email"))])},
    "paragraph43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our contact information is posted below:"])}
  },
  "privacyPolicy": {
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UAB Londesa company Privacy Policy"])},
    "paragraph1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from https://cityrush.lt (the “Site”)."])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PERSONAL INFORMATION WE COLLECT"])},
    "paragraph2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you visit the Site, we use cookies that are installed on your device. These cookies, however, are considered essential cookies and are necessary for the Site to work correctly with all of its functionality."])},
    "paragraph3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What are cookies: “Cookies” are data files that are placed on your device or computer and often include an nonymous unique identifier. For more information about cookies, and how to disable cookies, visit http://www.allaboutcookies.org."])},
    "paragraph4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additionally, when you make a purchase or attempt to make a purchase through the Site, we collect certain information from you, including your name, billing address, shipping address, payment information, email address, and phone number. We refer to this information as “Order Information.”"])},
    "paragraph5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When we talk about “Personal Information” in this Privacy Policy, we are talking about Order Information."])},
    "title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOW DO WE USE YOUR PERSONAL INFORMATION?"])},
    "paragraph6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We use the Order Information that we collect generally to fulfill any orders placed through the Site (including processing your payment information, arranging for shipping, and providing you with invoices and/or order confirmations). Additionally, we use this Order Information to: Communicate with you; Screen our orders for potential risk or fraud; and When in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services."])},
    "title4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SHARING YOUR PERSONAL INFORMATION"])},
    "paragraph7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to lawful request for information we receive, or to otherwise protect our rights."])},
    "title5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YOUR RIGHTS"])},
    "paragraph8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below. Additionally, if you are a European resident we note that we are processing your information in order to fulfill contracts we might have with you (for example if you make an order through the Site), or otherwise to pursue our legitimate business interests listed above."])},
    "title6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DATA RETENTION"])},
    "paragraph9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you place an order through the Site, we will maintain your Order Information for our recordsunless and until you ask us to delete this information."])},
    "title7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CHANGES"])},
    "paragraph10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons."])},
    "title8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTACT US"])},
    "paragraph11": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at ", _interpolate(_named("email")), " or by mail using the details provided below:"])},
    "paragraph12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dariaus ir Girėno g. 173, LT-02189 Vilnius"])}
  },
  "dataDeletionPolicy": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data deletion policy"])},
    "paragraph1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any data that is related to the user of this application is removed from this application if requested by mentioned user. Such data can include users name, surname, username, email, phone number, multiple addresses and any other data within this users account that is related to this user."])},
    "paragraph2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User can also by his request have his whole account or multiple accounts that he owns removed from this application"])}
  },
  "discountPoints": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount points"])},
    "theAmountOfPointsHaveExpiresAfter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The amount of points you have (expires after ", _interpolate(_named("date")), ")"])},
    "referralLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referral link"])},
    "linkToShare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link to share"])},
    "copyThisLinkAndShare": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Copy this link and share with others. When a new user makes his first purchase you will receive points. Amount of points per referral - ", _interpolate(_named("amount"))])},
    "readMoreAboutDiscountPoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read more about discount points"])},
    "maximumAllowedOrderPoints": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Maximum allowed order points (", _interpolate(_named("percentage")), "% of kits value) are going to be applied automatically"])}
  },
  "referral": {
    "referralLinkRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referral link registration"])},
    "weAreSorryCanNotRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are sorry but you can not register using referral link"])}
  },
  "kitOrderSetting": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kit order settings"])},
    "cancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellation"])},
    "disableUserCancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable user cancellation"])},
    "cancellationTermInHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellation term (in hours) "])},
    "enterCancellationTermInHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter cancellation term (in hours)"])},
    "amountOfHoursBeforeDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount of hours before delivery that user can cancel kits order"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])},
    "disableChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable change"])},
    "termForChangeHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Term for change (hours)"])},
    "enterTermForChangeHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter term for change (hours)"])},
    "timeAfterWhichChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time after which change to order will be made (from moment user makes change)"])},
    "deliveryDateChangeLimitHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery date change limit (hours)"])},
    "enterDeliveryDateChangeLimitHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter delivery date change limit (hours)"])},
    "howManyHoursBeforeStartOfDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How many hours before start of delivery user can change delivery date"])}
  },
  "kitManagement": {
    "has_canceled_by_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buyer has canceled the order"])},
    "has_canceled_by_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provider has canceled the order"])},
    "orderCanceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order canceled"])},
    "cancellationRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellation request"])},
    "cancellationRequestSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will get in contact with you shortly in regards to cancellation conditions"])},
    "regularOrderCancellationSubtitle1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cancellation of order (or reorder) should be made ", _interpolate(_named("hours")), "h before delivery, otherwise that days kit will be delivered and payment for that day will not be returned"])},
    "regularOrderCancellationSubtitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days order pricing will be applied to every delivered kit when calculating sum returned. Sum that is left after subtracted deliveries will be returned. Discounts are accounted for when calculating sum returned"])},
    "amountToBeReturned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount to be returned (applies only if payment made, some delivery costs could be returned as well)"])},
    "amountReturned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount returned"])},
    "confirmReturnedAmountModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to confirm returned amount?"])},
    "sendCancelationRequestModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to send cancelation request for this order?"])},
    "cancelOrderModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to cancel this order?"])},
    "continueOrderModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["'Are you sure you want to continue this order?"])},
    "cancelOrderModalDescription": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You will have ", _interpolate(_named("amount")), " Eur returned to you for the kit (some delivery costs could be returned as well)"])},
    "regularOrderStatuses": {
      "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
      "canceled_by_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel by provider"])},
      "canceled_by_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel by user"])},
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])},
      "not_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not paid"])}
    },
    "individualOrderStatuses": {
      "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
      "approved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approved"])},
      "cancellation_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellation request"])},
      "declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declined"])},
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])},
      "not_paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not paid"])}
    },
    "allChangesApartFromContactsWouldBeDoneAfter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["All changes apart from contacts would be done after ", _interpolate(_named("hours")), "h from moment of change"])},
    "allChangesApartFromDeliveryWouldBeDoneAfter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["All changes apart from delivery would be done after ", _interpolate(_named("hours")), "h from moment of change"])},
    "regularKitSelections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regular kit selections"])},
    "ifChangeIsMadeToRegularKit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If change is made to regular kit it would apply to all regular kits in order because delivery conditions are the same for all regular kits"])},
    "youCanChangeDeliveryDateBefore": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You can change delivery date and delivery time ", _interpolate(_named("hours")), "h before delivery"])}
  },
  "EUSupport": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New e-commerce solutions UAB, “Londesa”"])},
    "paragraph1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UAB Londesa since 2022 February implements a project for implementation of the e-commerce model. In order to carry out profitable activities in the COVID-19 affected market, as well as to increase the attractiveness of the services provided and the productivity of the employees, the project will create an electronic customer self-service system. In a modern mobile application, customers will be able to order food subscription (in Vilnius and Kaunas cities) on their own, as well as to participate in surveys (improving personal subscriptions), interacting directly with the company's staff and receiving important reports related to ordering."])},
    "paragraph2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful implementation of the project results will allow customers to obtain services faster and more conveniently, and the company will have the opportunity to manage orders more efficiently and monitor the demand for services offered and the products sold, with more customer needs and expectations."])},
    "paragraph3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The project is funded by the European Regional Development Fund, as the European Union responds to the Covid-19 pandemic."])},
    "projectTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project title:"])},
    "projectTitleText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UAB Londesa E-Commerce Model Implementation"])},
    "projectNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project number:"])},
    "projectNumberText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13.1.1-LVPA-K-860-01-1067"])},
    "projectStarts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The project starts:"])},
    "projectStartsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022. February 15"])},
    "projectEnds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End of the project:"])},
    "projectEndsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2023 January 8th"])},
    "projectValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project value:"])},
    "projectValueText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EUR 22 000 (including EUR 16 500 EU support)."])}
  },
  "cities": {
    "preOrderMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification when pre-order is enabled."])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])}
  },
  "pages": {
    "pages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pages"])},
    "noPagesAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No pages"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of the page"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pages"])},
    "slug": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page url"])},
    "noCitiesAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no cities."])},
    "cities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cities"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])}
  },
  "checkout": {
    "info_text": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After registering, you will be able to:"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See and manage your order"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You know exactly when the last day of your order is"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will accumulate loyalty points"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P.S. \nIf you place an order without registration, you will not see it in your account later."])}
    }
  },
  "errros": {
    "i_agreen_with_contact_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must agree to my specified contacts, to receive information regarding the progress of my order."])}
  },
  "unique_mealits": {
    "date_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The delivery of the kits will take place on the eve of December 23 or the morning of the 24th, depending on the selected city and delivery options; \nthe exact date will be agreed individually after placing the order."])},
    "time_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The delivery time will be arranged individually after placing the order, depending on your needs and our delivery capabilities."])}
  }
}