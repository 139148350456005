import Api from './api';

export class DishApi {
    static getAll = async (params) => {
        const response = await Api.axios.get('dishes', { params });
        return response.data;
    };

    static show = async (id) => {
        const response = await Api.axios.get(`dishes/${id}`);
        return response.data;
    };

    static store = async (data) => {
        const response = await Api.axios.post('dishes', data);
        return response.data;
    };

    static update = async (id, data) => {
        const response = await Api.axios.put(`dishes/${id}`, data);
        return response.data;
    };

    static destroy = async (id) => {
        const response = await Api.axios.delete(`dishes/${id}`);
        return response.data;
    };
}
