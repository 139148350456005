import Cookies from 'js-cookie';

export const orderInformation = {
    namespaced: true,
    state: {
        deliveryInformation: null,
        buyerInformation: null,
    },
    getters: {
        deliveryInformation: (state) => {
            return state.deliveryInformation;
        },
        buyerInformation: (state) => {
            return state.buyerInformation;
        },
    },
    mutations: {
        setDeliveryInformation(state, deliveryInformation) {
            state.deliveryInformation = deliveryInformation;
        },

        setBuyerInformation(state, buyerInformation) {
            state.buyerInformation = buyerInformation;
        },
    },
    actions: {
        setDeliveryInformation({ commit }, deliveryInformation) {
            const deliveryInformationCookie = Cookies.get('delivery-information');

            if (deliveryInformationCookie || deliveryInformation) {
                commit('setDeliveryInformation', deliveryInformation ?? JSON.parse(deliveryInformationCookie));
                Cookies.set('delivery-information', deliveryInformation ?? JSON.parse(deliveryInformationCookie), {
                    expires: 1 / 24,
                });
            }
        },

        setBuyerInformation({ commit }, buyerInformation) {
            const buyerInformationCookie = Cookies.get('buyer-information');

            if (buyerInformationCookie || buyerInformation) {
                commit('setBuyerInformation', buyerInformation ?? JSON.parse(buyerInformationCookie));
                Cookies.set('buyer-information', buyerInformation ?? JSON.parse(buyerInformationCookie), {
                    expires: 1 / 24,
                });
            }
        },
    },
};
