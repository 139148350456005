import { checkIfCheckOutExist } from '@/router/Guards/index.guard';

export default [
    {
        path: 'personal-settings',
        name: 'personalSettings',
        component: () => import('@/pages/User/PersonalSettings/PersonalSettings'),
    },
    {
        path: 'order-management',
        component: () => import('@/layouts/WrapperLayout.vue'),
        name: 'userOrderManagement',
        redirect: { name: 'allUserCheckOuts' },
        children: [
            {
                path: 'all',
                name: 'allUserCheckOuts',
                component: () => import('@/pages/OrderManagement/AllCheckOutsPage.vue'),
            },
            {
                path: ':check_out_id(\\d+)',
                name: 'userCheckOut',
                component: () => import('@/pages/OrderManagement/CheckOutPage.vue'),
                beforeEnter: checkIfCheckOutExist,
            },
        ],
    },
    {
        path: 'kit-management',
        name: 'kitManagement',
        component: () => import('@/pages/User/KitManagement/KitManagement'),
    },
    {
        path: 'addresses',
        name: 'addresses',
        component: () => import('@/pages/User/AddressesSettings/AddressesSettings'),
    },
    {
        path: 'discounts',
        name: 'discounts',
        component: () => import('@/pages/User/Discounts/Discounts'),
    },
];
