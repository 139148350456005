import { CityApi } from '@/api';
import LocalStorage from '@/helpers/LocalStorageHelper';

export const city = {
    namespaced: true,
    state: {
        cities: [],
        citiesForSelect: [],
        selectedCity: null,
        currentCity: {},
    },
    getters: {
        cities: (state) => {
            return state.cities;
        },
        citiesForSelect: (state) => {
            return state.citiesForSelect.filter((city) => city.active === true);
        },
        selectedCity: (state) => {
            return state.selectedCity;
        },
        currentCity: (state) => {
            return state.currentCity;
        },
    },
    mutations: {
        setCities(state, cities) {
            state.cities = cities;
        },
        setCitiesForSelect(state, cities) {
            state.citiesForSelect = cities;
        },
        selectCity(state, city) {
            state.selectedCity = city;
        },
        setCurrentCity(state, currentCity) {
            state.currentCity = currentCity;
        },
        deleteCity(state, id) {
            const cities = state.cities.filter((kit) => kit.id !== id);
            state.cities = [...cities];
        },
    },
    actions: {
        async getCity({ commit }, id) {
            const response = await CityApi.show(id);

            commit('setCurrentCity', response.data);
        },
        async getAllCities({ commit, dispatch }) {
            const response = await CityApi.getAll();
            const citiesForSelect = response.data.map((city) => {
                return {
                    label: city.name,
                    value: city.id,
                    active: city.active,
                };
            });

            commit('setCities', response.data);
            commit('setCitiesForSelect', citiesForSelect);
            dispatch('initialCitySelect');
        },

        async setSelectedCityId({ commit, state }, selectCityId) {
            const selectedCity = state.cities.find((city) => city.id === selectCityId);
            LocalStorage.create('selectedCity', selectedCity);
            commit('selectCity', selectedCity);
        },

        async setSelectedCity({ commit, state }, selectCity) {
            const selectedCity = state.cities.find((city) => city.id === selectCity?.value);
            LocalStorage.create('selectedCity', selectedCity);

            commit('selectCity', selectedCity);
        },

        async initialCitySelect({ commit, state }) {
            if (LocalStorage.show('selectedCity')) {
                commit('selectCity', LocalStorage.show('selectedCity'));
            } else {
                LocalStorage.create('selectedCity', state.cities[0]);
                commit('selectCity', state.cities[0]);
            }
        },
        async destroyCity({ commit }, id) {
            await CityApi.delete(id);

            commit('deleteCity', id);
        },
    },
};
