import LocalStorage from '@/helpers/LocalStorageHelper';

export const discountPoint = {
    namespaced: true,
    state: {
        userDiscountPoints: null,
        appliedDiscountPoints: null,
    },
    getters: {
        appliedDiscountPoints: (state) => {
            return state.appliedDiscountPoints;
        },

        userDiscountPoints: (state) => {
            return state.userDiscountPoints;
        },
    },
    mutations: {
        setUserDiscountPoints(state, userDiscountPoints) {
            state.userDiscountPoints = userDiscountPoints;
        },

        setAppliedDiscountPoints(state, appliedDiscountPoints) {
            state.appliedDiscountPoints = appliedDiscountPoints;

            if (appliedDiscountPoints) {
                state.userDiscountPoints.amount = state.userDiscountPoints.amount - appliedDiscountPoints.pointsToUse;
            }
        },
    },
    actions: {
        setUserDiscountPoints({ commit, dispatch }, userDiscountPoints) {
            commit('setUserDiscountPoints', userDiscountPoints);

            dispatch('setAppliedDiscountPoints');
        },

        setAppliedDiscountPoints({ commit }) {
            const discountPointsLocalStorage = LocalStorage.show('discount-points');

            if (discountPointsLocalStorage) {
                commit('setAppliedDiscountPoints', discountPointsLocalStorage);
            }
        },

        applyDiscountPoints({ commit, rootGetters }, discountPoints = null) {
            LocalStorage.create('discount-points', discountPoints);

            if (!discountPoints) {
                commit('setUserDiscountPoints', { ...rootGetters['auth/discountPoints'] });
            }

            commit('setAppliedDiscountPoints', discountPoints);
        },

        clearDiscountPoints({ commit, rootGetters }) {
            LocalStorage.delete('discount-points');

            commit('setUserDiscountPoints', { ...rootGetters['auth/discountPoints'] });

            commit('setAppliedDiscountPoints', null);
        },
    },
};
