export const ConversionEventNameEnum = {
    PURCHASE: 'Purchase',
    ADD_TO_CART: 'AddToCart',
    INITIATE_CHECK_OUT: 'InitiateCheckout',
    VIEW_CONTENT: 'ViewContent',
    ADD_PAYMENT_INFO: 'AddPaymentInfo',
    COMPLETE_REGISTRATION: 'CompleteRegistration',
    CONTACT: 'Contact',
    LEAD: 'Lead',
    CUSTOMIZE_PRODUCT: 'CustomizeProduct',
};
