import store from '@/store/index';

const checkIfPageExist = async (to, from, next) => {
    try {
        try {
            await store.dispatch('page/getPage', to.params.page_id);
        } catch (error) {
            console.error(error);
        }

        next();
    } catch (error) {
        console.log(error);
        next({ name: 'page-not-found' });
    }
};

const checkIfPageWithSlugExist = async (to, from, next) => {
    try {
        try {
            await store.dispatch('page/getPageBySlug', to.params.slug);
        } catch (error) {
            console.error(error);
        }

        next();
    } catch (error) {
        console.log(error);
        next({ name: 'page-not-found' });
    }
};

export { checkIfPageExist, checkIfPageWithSlugExist };
