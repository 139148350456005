import { checkIfBlogArticleExist } from '@/router/Guards/index.guard';

export default [
    {
        path: 'blog-articles',
        component: () => import('@/layouts/WrapperLayout.vue'),
        name: 'blogArticles',
        redirect: { name: 'allBlogArticles' },
        children: [
            {
                path: '',
                name: 'allBlogArticles',
                component: () => import('@/pages/BlogArticles/AllBlogArticles.vue'),
            },
            {
                path: ':blog_article_id(\\d+)',
                name: 'blogArticle',
                component: () => import('@/pages/BlogArticles/BlogArticle.vue'),
                beforeEnter: checkIfBlogArticleExist,
            },
        ],
    },
];
