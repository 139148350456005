import Cookies from 'js-cookie';
import { AuthApi, UserApi, AddressApi } from '@/api';

export const auth = {
    namespaced: true,
    state: {
        user: null,
        returnUrl: '',
    },
    getters: {
        user: (state) => {
            return state.user;
        },
        isAdmin: (state) => {
            return state.user?.role === 'admin';
        },
        referralLink: (state) => {
            return state.user?.referralLink.link;
        },
        discountPoints: (state) => {
            return state.user?.discountPoints;
        },
        returnUrl(state) {
            return state.returnUrl;
        }
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        },

        addAddress(state, address) {
            state.user.addresses.push(address);
        },

        deleteAddress(state, id) {
            const addresses = state.user.addresses.filter((address) => address.id !== id);
            state.user.addresses = [...addresses];
        },

        updateAddressById(state, newAddress) {
            let address = state.user.addresses.find((item) => item.id === newAddress.id);

            if (address) {
                const index = state.user.addresses.indexOf(address);
                state.user.addresses[index] = newAddress;
            }
        },
        
        setReturnUrl(state, url) {
            state.returnUrl = url;
        }
    },
    actions: {
        async register({ dispatch }, data) {
            const response = await AuthApi.register(data);
            Cookies.set('Authorization', `${response.authorization.type} ${response.authorization.token}`);
            dispatch('setUser', response.user);
        },

        async login({ dispatch }, data) {
            const response = await AuthApi.login(data);
            Cookies.set('Authorization', `${response.authorization.type} ${response.authorization.token}`);
            dispatch('setUser', response.user);
        },

        async logout({ dispatch }) {
            await AuthApi.logout();

            dispatch('removeAuthToken');
        },

        removeAuthToken({ commit }) {
            commit('setUser', null);
            Cookies.remove('Authorization');
        },

        setUser({ commit, dispatch }, user) {
            commit('setUser', user);
            dispatch('discountPoint/setUserDiscountPoints', { ...user.discountPoints }, { root: true });
        },

        async setAuthUser({ commit, dispatch }) {
            if (Cookies.get('Authorization')) {
                const response = await UserApi.getAuthUser();
                dispatch('setUser', response.data);
            } else {
                commit('setUser', null);
            }
        },

        async storeAddress({ commit }, data) {
            const response = await AddressApi.store(data);
            commit('addAddress', response.data);
        },

        async updateAddress({ commit }, data) {
            const response = await AddressApi.update(data.id, data);
            commit('updateAddressById', response.data);
        },

        async destroyAddress({ commit }, id) {
            await AddressApi.destroy(id);
            commit('deleteAddress', id);
        },

        async updateUser({ commit }, payload) {
            const response = await UserApi.update(payload.id, payload.data);
            commit('setUser', response.data);
        },
    },
};
