export const orderPrices = {
    namespaced: true,
    state: {
        prices: null,
    },
    getters: {
        prices: (state) => {
            return state.prices;
        },
    },
    mutations: {
        setPrices(state, prices) {
            state.prices = prices;
        },
    },
};
