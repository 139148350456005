import Api from './api';

export class AllergenApi {
    static getAll = async (params) => {
        const response = await Api.axios.get('allergens', { params });
        return response.data;
    };

    static show = async (id) => {
        const response = await Api.axios.get(`allergens/${id}`);
        return response.data;
    };

    static update = async (id, data) => {
        const response = await Api.axios.put(`allergens/${id}`, data);
        return response.data;
    };

    static destroy = async (id) => {
        const response = await Api.axios.delete(`allergens/${id}`);
        return response.data;
    };

    static store = async (data) => {
        const response = await Api.axios.post('allergens', data);
        return response.data;
    };
}
