import store from '@/store/index';

const checkIfMealKitExist = async (to, from, next) => {
    try {
        try {
            await store.dispatch('mealKit/getMealKit', to.params.meal_kit_id);
            const currentMealKit = store.getters['mealKit/currentMealKit'];

            if (!currentMealKit.isHidden || store.getters['auth/isAdmin']) {
                next();
            } else {
                next({ name: 'page-not-found' });
            }
        } catch (error) {
            this.$filters.toast(error.message);
        }
    } catch (error) {
        next({ name: 'page-not-found' });
    }
};

export { checkIfMealKitExist };
