import Api from './api';

export class AdditionApi {
    static getAll = async () => {
        const response = await Api.axios.get('additions');
        return response.data;
    };

    static show = async (id) => {
        const response = await Api.axios.get(`additions/${id}`);
        return response.data;
    };

    static update = async (id, data) => {
        const response = await Api.axios.put(`additions/${id}`, data);
        return response.data;
    };

    static store = async (data) => {
        const response = await Api.axios.post('additions', data);
        return response.data;
    };

    static destroy = async (id) => {
        const response = await Api.axios.delete(`additions/${id}`);
        return response.data;
    };
}
