import Api from './api';

export class BlogArticleApi {
    static getAll = async (params) => {
        const response = await Api.axios.get('blog-articles', { params });
        return response.data;
    };

    static show = async (id) => {
        const response = await Api.axios.get(`blog-articles/${id}`);
        return response.data;
    };

    static store = async (data) => {
        const response = await Api.axios.post('blog-articles', data, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
        return response.data;
    };

    static update = async (data, id) => {
        const response = await Api.axios.put(`blog-articles/${id}`, data);
        return response.data;
    };

    static destroy = async (id) => {
        const response = await Api.axios.delete(`blog-articles/${id}`);
        return response.data;
    };
}
