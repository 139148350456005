import Api from './api';

export class PersonalMenuApi {
    static getAll = async (params) => {
        const response = await Api.axios.get('personal-menus', { params });
        return response.data;
    };

    static store = async (data) => {
        const response = await Api.axios.post('personal-menus', data);
        return response.data;
    };

    static update = async (id, data) => {
        const response = await Api.axios.post(`personal-menus/${id}`, data);
        return response.data;
    };

    static destroy = async (id) => {
        const response = await Api.axios.delete(`personal-menus/${id}`);
        return response.data;
    };

    static destroyFile = async (id) => {
        const response = await Api.axios.post(`personal-menus/destroy-file/${id}`);
        return response.data;
    };
}
