import Cookies from 'js-cookie';
import store from '@/store/index';
import { UserRoleEnum } from '@/utils/enums';

const authRoutesGuard = (to, from, next) => {
    if (Cookies.get('Authorization')) {
        next({ name: 'main' });
    } else {
        next();
    }
};

const authorizationGuard = (to, from, next) => {
    if (Cookies.get('Authorization')) {
        next();
    } else {
        next({ name: 'main' });
    }
};

const adminAuthorizationGuard = async (to, from, next) => {
    if (Cookies.get('Authorization')) {
        const user = store.getters['auth/user'];

        if (user.role === UserRoleEnum.ADMIN) {
            next();
        } else {
            next({ name: 'main' });
        }
    } else {
        next({ name: 'main' });
    }
};

export { authRoutesGuard, adminAuthorizationGuard, authorizationGuard };
