import Api from './api';

export class FAQSectionApi {
    static getAll = async () => {
        const response = await Api.axios.get('faq-sections');
        return response.data;
    };

    static delete = async (id) => {
        const response = await Api.axios.delete(`faq-sections/${id}`);
        return response.data;
    };

    static store = async (data) => {
        const response = await Api.axios.post('faq-sections', data);
        return response.data;
    };

    static update = async (id, data) => {
        const response = await Api.axios.put(`faq-sections/${id}`, data);
        return response.data;
    };
}
