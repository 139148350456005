import Api from './api';

export class FAQItemsApi {
    static getAll = async () => {
        const response = await Api.axios.get('faq-items');
        return response.data;
    };

    static delete = async (id) => {
        const response = await Api.axios.delete(`faq-items/${id}`);
        return response.data;
    };

    static store = async (data) => {
        const response = await Api.axios.post('faq-items', data);
        return response.data;
    };

    static update = async (id, data) => {
        const response = await Api.axios.put(`faq-items/${id}`, data);
        return response.data;
    };
}
