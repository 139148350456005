import { checkIfMealKitExist } from '@/router/Guards/index.guard';

export default [
    {
        path: 'mitybos-planai',
        component: () => import('@/layouts/WrapperLayout.vue'),
        name: 'mealKits',
        redirect: { name: 'allMealKits' },
        children: [
            {
                path: '',
                name: 'allMealKits',
                component: () => import('@/pages/MealKits/AllMealKits.vue'),
            },
            {
                path: ':meal_kit_id(\\d+)',
                name: 'mealKit',
                component: () => import('@/pages/MealKits/MealKit.vue'),
                beforeEnter: checkIfMealKitExist,
            },
        ],
    },
];
