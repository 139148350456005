import Api from './api';

export class DietPlanApi {
    static getAll = async () => {
        const response = await Api.axios.get('diet-plans');
        return response.data;
    };

    static store = async (data) => {
        const response = await Api.axios.post('diet-plans', data);
        return response.data;
    };

    static update = async (data, id) => {
        const response = await Api.axios.put(`diet-plans/${id}`, data);
        return response.data;
    };

    static destroy = async (id) => {
        const response = await Api.axios.delete(`diet-plans/${id}`);
        return response.data;
    };
}
