import { DiscountTypeEnum } from '@/utils/enums';

export const getSign = (key) => {
    switch (key) {
        case DiscountTypeEnum.FIXED:
            return '€';
        case DiscountTypeEnum.PERCENTAGE:
            return '%';
        default:
            return '';
    }
};
