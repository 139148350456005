import { DishApi, MenuApi } from '@/api';
import { WeekTypeEnum } from '@/utils/enums';

export const menu = {
    namespaced: true,
    state: {
        nextWeekGroupedMenu: null,
        currentWeekGroupedMenu: null,
    },
    getters: {
        nextWeekGroupedMenu: (state) => {
            return state.nextWeekGroupedMenu;
        },
        currentWeekGroupedMenu: (state) => {
            return state.currentWeekGroupedMenu;
        },
    },
    mutations: {
        setGroupedWeekMenu(state, menu) {
            if (menu.week === WeekTypeEnum.CURRENT) state.currentWeekGroupedMenu = menu.data;
            if (menu.week === WeekTypeEnum.NEXT) state.nextWeekGroupedMenu = menu.data;
        },

        updateDishesInMenus(state, payload) {
            let menus = [state.nextWeekGroupedMenu, state.currentWeekGroupedMenu];

            menus.forEach((neededMenu) => {
                let week = neededMenu[payload.menu.type];
                let menusToChange = Object.keys(week);

                menusToChange.forEach((day) => {
                    week[day].forEach((meal) => {
                        if (meal?.dish.id === payload.dishId) {
                            meal.dish = payload.dish;
                        }
                    });
                });
            });
        },

        syncDishWithMenu(state, payload) {
            const neededWeekGroupMenu =
                payload.menu.weekType === WeekTypeEnum.NEXT ? state.nextWeekGroupedMenu : state.currentWeekGroupedMenu;

            const oldMenu = neededWeekGroupMenu[payload.menu.type][payload.menu.day].find(
                (item) => item.id === payload.menu.id
            );

            oldMenu.dish = payload.dish;
        },
    },
    actions: {
        async groupedWeekMenu({ commit }, params) {
            const nextWeekGroupedMenu = await MenuApi.groupedWeekMenu(params);
            commit('setGroupedWeekMenu', nextWeekGroupedMenu);
        },

        async getAllGroupedMenus({ dispatch }, type) {
            await dispatch('groupedWeekMenu', { type, weekType: WeekTypeEnum.NEXT });
            await dispatch('groupedWeekMenu', { type, weekType: WeekTypeEnum.CURRENT });
        },

        async addDishInMenu({ commit }, payload) {
            const data = {
                dishId: payload.dishId,
            };

            const response = await MenuApi.addDish(payload.menuId, data);

            const dataForUpdate = {
                menu: response.data,
                dish: response.data.dish,
            };

            commit('syncDishWithMenu', dataForUpdate);
        },

        async removeDishFromMenu({ commit }, payload) {
            const response = await MenuApi.removeDish(payload.menuId);
            await DishApi.destroy(payload.dishId);

            const dataForUpdate = {
                menu: response.data,
                dish: response.data.dish,
                dishId: payload.dishId,
            };

            commit('updateDishesInMenus', dataForUpdate);
        },
    },
};
