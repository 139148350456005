import i18n from '@/i18n/i18n';

export const translation = (key, params) => {
    return i18n.global.t(key, params);
};

export const getTranslationByKey = (nameObj) => {
    const locale = i18n.global.locale._value;

    if (nameObj) {
        return nameObj[locale] ? nameObj[locale] : nameObj.lt;
    }

    return nameObj;
};
