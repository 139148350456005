import Api from './api';

export class IndividualOrderSettingsApi {
    static getIndividualOrderSettings = async () => {
        const response = await Api.axios.get('individual-order-settings');
        return response.data;
    };

    static update = async (data, id) => {
        const response = await Api.axios.put(`individual-order-settings/${id}`, data);
        return response.data;
    };
}
