import Api from './api';

export class FeedBackApi {
    static getAll = async () => {
        const response = await Api.axios.get('feedbacks');
        return response.data;
    };

    static store = async (data) => {
        const response = await Api.axios.post('feedbacks', data);
        return response.data;
    };
}
