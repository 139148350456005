import { deliveryCheckOutGuard, buyerInfoCheckOutGuard, paymentCheckOutGuard } from '@/router/Guards/index.guard';

export default [
    {
        path: 'kuciu-rinkinys',
        component: () => import('@/pages/OrderManagement/CristmasEveForm'),
        name: 'CristmasEveForm',
    },
    {
        path: 'check-out',
        component: () => import('@/layouts/CheckOutLayout.vue'),
        name: 'checkOut',
        redirect: { name: 'checkOutBuyerInfo' },
        children: [
            {
                path: 'buyer-info',
                name: 'checkOutBuyerInfo',
                component: () => import('@/pages/CheckOut/BuyerInfo.vue'),
                beforeEnter: buyerInfoCheckOutGuard,
            },
            {
                path: 'delivery',
                name: 'checkOutDelivery',
                component: () => import('@/pages/CheckOut/Delivery.vue'),
                beforeEnter: deliveryCheckOutGuard,
            },
            {
                path: 'payment',
                name: 'checkOutPayment',
                component: () => import('@/pages/CheckOut/PaymentPage.vue'),
                beforeEnter: paymentCheckOutGuard,
            },
        ],
    },
];
