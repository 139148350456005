import Api from './api';

export class PaymentApi {
    static montonioWebhook = async (data) => {
        const response = await Api.axios.post('payments/notification-webhook', data);
        return response.data;
    };

    static montonioPaymentMethods = async () => {
        const response = await Api.axios.get('payments/payment-methods');
        return response.data.paymentMethods;
    }
}
