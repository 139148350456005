import Api from './api';
import Cookies from 'js-cookie';

export class AuthApi {
    static login = async (data) => {
        const response = await Api.axios.post('auth/login', data);
        return response.data;
    };

    static register = async (data) => {
        const response = await Api.axios.post('auth/register', data);
        return response.data;
    };

    static logout = async () => {
        return await Api.axios.post('auth/logout');
    };

    static refresh = async () => {
        const response = await Api.axios.post(
            'auth/refresh',
            {},
            {
                headers: { authorization: Cookies.get('Authorization') },
            }
        );

        return response.data;
    };

    static socialRedirect = async (provider) => {
        const response = await Api.axios.get(`social/${provider}`);
        return response.data;
    };

    static socialCallback = async (provider, params) => {
        const response = await Api.axios.get(`social/${provider}/callback`, { params });
        return response.data;
    };
}
