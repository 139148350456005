import Api from './api';

export class IndividualOrderApi {
    static getAll = async (params = {}) => {
        const response = await Api.axios.get('individual-orders', { params });
        return response.data;
    };

    static store = async (data) => {
        const response = await Api.axios.post('individual-orders', data);
        return response.data;
    };

    static update = async (id, data) => {
        const response = await Api.axios.put(`individual-orders/${id}`, data);
        return response.data;
    };

    static payment = async (data) => {
        const response = await Api.axios.post('individual-orders/pay', data);
        return response.data;
    };
}
