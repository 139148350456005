import Api from './api';

export class DiscountApi {
    static getAll = async () => {
        const response = await Api.axios.get('discounts');
        return response.data;
    };

    static store = async (data) => {
        const response = await Api.axios.post('discounts', data);
        return response.data;
    };

    static destroy = async (id) => {
        const response = await Api.axios.delete(`discounts/${id}`);
        return response.data;
    };
}
