/* eslint-disable no-unused-vars */
import _ from 'lodash';

export const errorTransformer = (error) => {
    const errorCodeArray = error.response?.data?.errors ?? null;
    const errorMessage = error.response ? error.response.data : error;
    const statusCode = errorMessage ? errorMessage.status_code : null;

    if (errorCodeArray && statusCode !== 401 && typeof errorCodeArray !== 'string') {
        let multipleErrors = '';

        Object.keys(errorCodeArray).map((errorKey) => {
            errorCodeArray[errorKey].map((singleError) => {
                multipleErrors += `${singleError} \n\r`;
            });

            const value = errorCodeArray[errorKey];
            delete errorCodeArray[errorKey];

            if (errorKey.split('.')[1] === 'lt' || errorKey.split('.')[1] === 'en') {
                errorCodeArray[_.camelCase(errorKey.split('.')[0])] = value;
            } else {
                errorCodeArray[_.camelCase(errorKey)] = value;
            }
        });
    }

    throw errorMessage;
};

export const convertObjectKeys = (object, func) => {
    func = func || _.snakeCase;
    if (!_.isObject(object)) {
        return object;
    }
    return _.mapValues(
        _.mapKeys(object, (value, key) => func(key)),
        (value) => {
            if (_.isObject(value)) {
                if (_.isArray(value)) {
                    return _.map(value, (val) => convertObjectKeys(val, func));
                } else {
                    return convertObjectKeys(value, func);
                }
            }
            return value;
        }
    );
};
