export const CheckOutStatusEnum = {
    // Individual
    ORDER_REQUEST: 'order_request',
    ORDER_APPROVED: 'order_approved',

    // Common
    NEW: 'new',
    WAITING_PAYMENT: 'waiting_payment',
    DECLINED: 'declined',
    PAID: 'paid',
    NOT_PAID: 'not_paid',
    COMPLETED: 'completed',
    STOPPED: 'stopped',
};
