import Api from './api';

export class CombinationApi {
    static getAll = async () => {
        const response = await Api.axios.get('combinations');
        return response.data;
    };

    static store = async (data) => {
        const response = await Api.axios.post('combinations', data);
        return response.data;
    };

    static destroy = async (id) => {
        const response = await Api.axios.delete(`combinations/${id}`);
        return response.data;
    };
}
