import Api from './api';

export class PaymentMethodApi {
    static getAll = async () => {
        const response = await Api.axios.get('payment-methods');
        return response.data;
    };

    static update = async (id, data) => {
        const response = await Api.axios.put(`payment-methods/${id}`, data);
        return response.data;
    };
}
