import { createStore } from 'vuex';
import { auth } from '@/store/modules/auth';
import { city } from '@/store/modules/city';
import { dish } from '@/store/modules/dish';
import { menu } from '@/store/modules/menu';
import { timer } from '@/store/modules/timer';
import { mealKit } from '@/store/modules/mealKit';
import { cart } from '@/store/modules/cart';
import { individualOrder } from '@/store/modules/individualOrder';
import { checkOut } from '@/store/modules/checkOut';
import { orderManagement } from '@/store/modules/orderManagement';
import { blogArticle } from '@/store/modules/blogArticle';
import { discountPoint } from '@/store/modules/discountPoint';
import { orderInformation } from '@/store/modules/orderInformation';
import { orderPrices } from '@/store/modules/orderPrices';
import { page } from '@/store/modules/page';
import { kitManagement } from '@/store/modules/kitManagement';

export default createStore({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        auth,
        city,
        timer,
        dish,
        menu,
        mealKit,
        cart,
        checkOut,
        individualOrder,
        orderManagement,
        blogArticle,
        discountPoint,
        orderInformation,
        orderPrices,
        kitManagement,
        page,
    },
});
