import store from '@/store/index';

const checkIfCheckOutExist = async (to, from, next) => {
    try {
        try {
            await store.dispatch('orderManagement/getCheckOut', to.params.check_out_id);
        } catch (error) {
            this.$filters.toast(error.message);
        }

        next();
    } catch (error) {
        next({ name: 'page-not-found' });
    }
};

const deliveryCheckOutGuard = async (to, from, next) => {
    store.dispatch('orderInformation/setBuyerInformation');

    const buyerInformation = store.getters['orderInformation/buyerInformation'];

    if (buyerInformation) {
        next();
    } else {
        next({ name: 'checkOutBuyerInfo' });
    }
};

const buyerInfoCheckOutGuard = async (to, from, next) => {
    store.dispatch('cart/setCart');

    const cart = store.getters['cart/cart'];

    if (cart.length) {
        next();
    } else {
        next({ name: 'main' });
    }
};

const paymentCheckOutGuard = async (to, from, next) => {
    store.dispatch('orderInformation/setDeliveryInformation');

    const deliveryInformation = store.getters['orderInformation/deliveryInformation'];

    if (deliveryInformation) {
        next();
    } else {
        next({ name: 'checkOutDelivery' });
    }
};

export { deliveryCheckOutGuard, buyerInfoCheckOutGuard, paymentCheckOutGuard, checkIfCheckOutExist };
