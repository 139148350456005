import Api from './api';

export class CheckOutApi {
    static getAll = async (params = {}) => {
        const response = await Api.axios.get('check-outs', { params });
        return response.data;
    };

    static show = async (id) => {
        const response = await Api.axios.get(`check-outs/${id}`);
        return response.data;
    };

    static update = async (id, data) => {
        const response = await Api.axios.put(`check-outs/${id}`, data);
        return response.data;
    };

    static delete = async (id) => {
        const response = await Api.axios.delete(`check-outs/${id}`);
        return response.data;
    };

    static restore = async (id) => {
        const response = await Api.axios.post(`check-outs/${id}/restore`);
        return response.data;
    };
}
