import Api from './api';

export class TimerApi {
    static getTimer = async () => {
        const response = await Api.axios.get('timer');
        return response.data;
    };

    static update = async (id, data) => {
        const response = await Api.axios.put(`timer/${id}`, data);
        return response.data;
    };
}
