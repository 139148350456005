import Api from './api';

export class EarliestDeliveryDayApi {
    static getAll = async () => {
        const response = await Api.axios.get('earliest-delivery-days');
        return response.data;
    };

    static update = async (id, data) => {
        const response = await Api.axios.put(`earliest-delivery-days/${id}`, data);
        return response.data;
    };
}
