import Api from './api';

export class UserApi {
    static getAuthUser = async () => {
        const response = await Api.axios.get('users/user-profile');
        return response.data;
    };

    static update = async (id, data) => {
        const response = await Api.axios.put(`users/${id}`, data);
        return response.data;
    };
}
