import Api from './api';

export class MenuApi {
    static groupedWeekMenu = async (params) => {
        const response = await Api.axios.get('menus/grouped/', { params });
        return { data: response.data, week: response.config.params.week_type };
    };

    static addDish = async (menuId, data) => {
        const response = await Api.axios.post(`menus/${menuId}/add-dish`, data);
        return response.data;
    };

    static removeDish = async (menuId) => {
        const response = await Api.axios.post(`menus/${menuId}/remove-dish`);
        return response.data;
    };
}
