import store from '@/store/index';

const individualDeliveryGuard = async (to, from, next) => {
    store.dispatch('individualOrder/setIndividualOrder');

    const orderState = store.getters['individualOrder/orderState'];

    if (orderState) {
        next();
    } else {
        next({ name: 'individualOrderKit' });
    }
};

const individualBuyerInfoGuard = async (to, from, next) => {
    store.dispatch('orderInformation/setDeliveryInformation');

    const deliveryInformation = store.getters['orderInformation/deliveryInformation'];

    if (deliveryInformation) {
        next();
    } else {
        next({ name: 'individualOrderDelivery' });
    }
};

const submitCheckOutGuard = async (to, from, next) => {
    store.dispatch('orderInformation/setBuyerInformation');

    const buyerInformation = store.getters['orderInformation/buyerInformation'];

    if (buyerInformation) {
        next();
    } else {
        next({ name: 'individualOrderBuyerInfo' });
    }
};

export { individualDeliveryGuard, individualBuyerInfoGuard, submitCheckOutGuard };
