import Api from './api';

export class ReferralSettingApi {
    static get = async () => {
        const response = await Api.axios.get('referral-settings');
        return response.data;
    };

    static update = async (id, data) => {
        const response = await Api.axios.put(`referral-settings/${id}`, data);
        return response.data;
    };
}
