import Api from './api';

export class DeliveryPriceApi {
    static getAll = async (checkout) => {
        const response = await Api.axios.get(`delivery-prices?checkout=${(checkout === undefined ? false : checkout)}`);
        return response.data;
    };

    static update = async (id, data) => {
        const response = await Api.axios.put(`delivery-prices/${id}`, data);
        return response.data;
    };
}
