import Api from './api';

export class PageApi {
    static getAll = async (params = {}) => {
        const response = await Api.axios.get('pages', { params });
        return response.data;
    };

    static show = async (id) => {
        const response = await Api.axios.get(`pages/${id}`);
        return response.data;
    };

    static showBySlug = async (slug) => {
        const response = await Api.axios.get(`p/${slug}`);
        return response.data;
    }

    static store = async (data) => {
        const response = await Api.axios.post('pages', data);
        return response.data;
    };

    static update = async (data, id) => {
        const response = await Api.axios.put(`pages/${id}`, data);
        return response.data;
    };

    static delete = async (id) => {
        const response = await Api.axios.delete(`pages/${id}`);
        return response.data;
    };
}
