import { PageApi } from '@/api';

export const page = {
    namespaced: true,
    state: {
        pages: [],
        currentPage: null,
        meta: {
            pageCount: 1,
            currentPage: 1,
            totalCount: 0,
            perPage: 0,
        },
        paramsData: {
            page: 1,
        },
    },
    getters: {
        pages: (state) => {
            return state.pages;
        },
        meta: (state) => {
            return state.meta;
        },
        currentPage: (state) => {
            return state.currentPage;
        },
    },
    mutations: {
        setPage(state, pages) {
            state.pages = pages;
        },

        setMeta(state, meta) {
            state.meta = meta;
        },

        setCurrentPage(state, currentPage) {
            state.currentPage = currentPage;
        },

        updateParamsField(state, payload) {
            Object.entries(payload).forEach((element) => {
                state.paramsData[element[0]] = element[1];

                if (element[0] !== 'page') {
                    state.paramsData.page = 1;
                }
            });
        },

        deletePage(state, id) {
            const pages = state.pages.filter((kit) => kit.id !== id);
            state.pages = [...pages];
        },
    },
    actions: {
        async getAllPages({ commit, state }, payload = {}) {
            commit('updateParamsField', payload);

            const response = await PageApi.getAll(state.paramsData);

            const meta = {
                pageCount: response.meta.last_page,
                currentPage: response.meta.current_page,
                totalCount: response.meta.total,
                perPage: response.meta.to - response.meta.from + 1,
            };

            commit('setPage', response.data);
            commit('setMeta', meta);
        },
        async getPageBySlug({ commit }, slug) {
            const response = await PageApi.showBySlug(slug);
            commit('setCurrentPage', response.data);
        },
        async getPage({ commit }, id) {
            const response = await PageApi.show(id);

            commit('setCurrentPage', response.data);
        },

        async destroyPage({ commit }, id) {
            await PageApi.delete(id);

            commit('deletePage', id);
        },
    },
};
