import { CheckOutTypeEnum } from '@/utils/enums';
import { IndividualOrderApi, OrderApi } from '@/api';

export const kitManagement = {
    namespaced: true,
    state: {
        orders: [],
        selectedOrderType: CheckOutTypeEnum.REGULAR_ORDER,
        meta: {
            pageCount: 1,
            currentPage: 1,
            totalCount: 0,
            perPage: 0,
        },
        paramsData: {
            status: '',
            paymentType: '',
            selectedSortBy: 1,
            sortField: 'created_at',
            sortDirection: 'desc',
            page: 1,
        },
    },
    getters: {
        orders: (state) => {
            return state.orders;
        },

        meta: (state) => {
            return state.meta;
        },

        selectedOrderType: (state) => {
            return state.selectedOrderType;
        },

        isRegularOrdersShown: (state) => {
            return state.selectedOrderType === CheckOutTypeEnum.REGULAR_ORDER;
        },

        isIndividualsOrdersShown: (state) => {
            return state.selectedOrderType === CheckOutTypeEnum.INDIVIDUAL_ORDER;
        },

        params: (state) => {
            return state.paramsData;
        },
    },
    mutations: {
        setOrders(state, orders) {
            state.orders = orders;
        },

        setMeta(state, meta) {
            state.meta = meta;
        },

        setOrderType(state, selectedOrderType) {
            state.selectedOrderType = selectedOrderType;
        },

        updateOrderStatus(state, payload) {
            let order = state.orders.find((order) => order.id === payload.id);

            if (order) {
                order.status = payload.order.status;
            }

            if (order.checkOut.type === CheckOutTypeEnum.REGULAR_ORDER) {
                order.moneyBack = payload.order.moneyBack;
            }
        },

        updateParamsField(state, payload) {
            Object.entries(payload).forEach((element) => {
                state.paramsData[element[0]] = element[1];

                if (element[0] !== 'page') {
                    state.paramsData.page = 1;
                }
            });
        },
    },
    actions: {
        async getOrders({ commit, state, getters }, payload = {}) {
            commit('updateParamsField', payload);

            const Api = getters.isRegularOrdersShown ? OrderApi : IndividualOrderApi;
            const response = await Api.getAll(state.paramsData);

            const meta = {
                pageCount: response.meta.last_page,
                currentPage: response.meta.current_page,
                totalCount: response.meta.total,
                perPage: response.meta.to - response.meta.from + 1,
            };

            commit('setOrders', response.data);
            commit('setMeta', meta);
        },

        async setOrderType({ commit, dispatch }, type) {
            commit('setOrderType', type);
            commit('setOrders', []);
            await dispatch('getOrders');
        },
    },
};
