import Api from './api';

export class DeliveryTimeApi {
    static getAll = async (city_id) => {        
        let url = 'delivery-times';
        if(city_id !== undefined) {
            url = url.concat(`?city_id=${city_id}`)
        }
        const response = await Api.axios.get(url);
        return response.data;
    };

    static store = async (data) => {
        const response = await Api.axios.post('delivery-times', data);
        return response.data;
    };

    static update = async (id, data) => {
        const response = await Api.axios.put(`delivery-times/${id}`, data);
        return response.data;
    };

    static destroy = async (id) => {
        const response = await Api.axios.delete(`delivery-times/${id}`);
        return response.data;
    };
}
