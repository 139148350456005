import Api from './api';

export class DishReviewApi {
    static getAll = async () => {
        const response = await Api.axios.get('dish-reviews');
        return response.data;
    };

    static store = async (data) => {
        const response = await Api.axios.post('dish-reviews', data);
        return response.data;
    };

    static destroy = async (id) => {
        const response = await Api.axios.delete(`dish-reviews/${id}`);
        return response.data;
    };
}
