import { createApp } from 'vue';
import { getTranslationByKey } from '@/helpers/TranslationHelper';
import { clearError } from '@/helpers/ErrorHelper';
import { toast } from '@/helpers/ToastHelper';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n/i18n';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import VueSocialSharing from 'vue-social-sharing';
import VueGtag from 'vue-gtag';

const app = createApp(App);

app.config.globalProperties.$filters = {
    getTranslationByKey,
    clearError,
    toast,
};

app.use(VueGtag, { config: { id: api.env.GOOGLE_ANALYTICS_KEY } });

app.use(VueSocialSharing).use(Toast).use(i18n).use(store).use(router).mount('#app');
