import Api from './api';

export class PromotionTextApi {
    static get = async () => {
        const response = await Api.axios.get('promotion-texts');
        return response.data;
    };

    static update = async (data, id) => {
        const response = await Api.axios.put(`promotion-texts/${id}`, data);
        return response.data;
    };
}
