export default [
    {
        path: 'login',
        component: () => import('@/pages/Login/Login.vue'),
        name: 'login',
    },
    {
        path: 'register',
        component: () => import('@/pages/Register/Register.vue'),
        name: 'register',
    },
    {
        path: 'forgot-password',
        component: () => import('@/pages/ForgotPassword/ForgotPassword.vue'),
        name: 'forgotPassword',
    },
    {
        path: 'reset-password',
        component: () => import('@/pages/ForgotPassword/ResetPassword.vue'),
        name: 'resetPassword',
    },
    {
        path: 'social/:provider/callback',
        component: () => import('@/pages/Social/Social.vue'),
        name: 'social',
    },
];
