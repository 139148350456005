import Api from './api';

export class MealKitApi {
    static getAll = async () => {
        const response = await Api.axios.get('meal-kits');
        return response.data;
    };

    static show = async (id) => {
        const response = await Api.axios.get(`meal-kits/${id}`);
        return response.data;
    };

    static store = async (data) => {
        const response = await Api.axios.post('meal-kits', data, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
        return response.data;
    };

    static update = async (data, id) => {
        const response = await Api.axios.put(`meal-kits/${id}`, data);
        return response.data;
    };

    static destroy = async (id) => {
        const response = await Api.axios.delete(`meal-kits/${id}`);
        return response.data;
    };

    static changeVisibility = async (id, data) => {
        const response = await Api.axios.post(`meal-kits/${id}/hide`, data);
        return response.data;
    };

    static getRecommended = async (data) => {
        const response = await Api.axios.post('meal-kits/recommended', data);
        return response.data;
    };
}
