import Api from './api';

export class DeliveryMethodApi {
    static getAll = async (params) => {
        const response = await Api.axios.get('delivery-methods', { params });
        return response.data;
    };

    static update = async (id, data) => {
        const response = await Api.axios.put(`delivery-methods/${id}`, data);
        return response.data;
    };
}
