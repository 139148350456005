import { MealKitApi, IndividualOrderSettingsApi } from '@/api';

export const mealKit = {
    namespaced: true,
    state: {
        regularKits: [],
        uniqueKits: [],
        individualOrderSettings: null,
        allMealKitsWithIO: [],
        currentMealKit: null,
    },
    getters: {
        regularKits: (state) => {
            return state.regularKits;
        },
        shownRegularKits: (state) => {
            return state.regularKits.filter((mealKit) => !mealKit.isHidden);
        },
        uniqueKits: (state) => {
            return state.uniqueKits;
        },
        individualOrderSettings: (state) => {
            return state.individualOrderSettings;
        },
        allShownMealKits: (state) => {
            const allMealKits = state.regularKits.concat(state.uniqueKits);
            return allMealKits.filter((mealKit) => !mealKit.isHidden);
        },
        allMealKitsWithIO: (state) => {
            return state.allMealKitsWithIO;
        },

        currentMealKit: (state) => {
            return state.currentMealKit;
        },
    },
    mutations: {
        setRegularKits(state, regularKits) {
            state.regularKits = regularKits;
        },

        setUniqueKits(state, uniqueKits) {
            state.uniqueKits = uniqueKits;
        },

        setIndividualOrderSettings(state, individualOrderSettings) {
            state.individualOrderSettings = individualOrderSettings;
        },

        setAllMealKitsWithIO(state, payload) {
            // prepare individualOrderSettings
            payload.individualOrderSettings.isIndividual = true;
            payload.individualOrderSettings.image = payload.individualOrderSettings.picture?.picturePath;

            // prepare regularKits
            payload.regularKits = payload.regularKits.map((mealKit) => {
                return {
                    ...mealKit,
                    price: mealKit.calories[0].prices[0].price,
                };
            });

            // prepare regularKits and uniqueKits
            let allMealKits = payload.regularKits.concat(payload.uniqueKits);
            allMealKits = allMealKits.map((mealKit) => {
                return {
                    ...mealKit,
                    isIndividual: false,
                    image: mealKit.pictures[0]?.picturePath,
                };
            });

            allMealKits.push(payload.individualOrderSettings);
            allMealKits = allMealKits.filter((mealKit) => !mealKit.isHidden);

            state.allMealKitsWithIO = allMealKits;
        },

        setCurrentMealKit(state, currentMealKit) {
            state.currentMealKit = currentMealKit;
        },

        deleteMealKit(state, payload) {
            let neededState = payload.isUnique ? 'uniqueKits' : 'regularKits';

            const mealKits = state[neededState].filter((kit) => kit.id !== payload.id);
            state[neededState] = [...mealKits];
        },

        updateMealKit(state, newMealKit) {
            let neededState = newMealKit.isUnique ? 'uniqueKits' : 'regularKits';

            let mealKit = state[neededState].find((item) => item.id === newMealKit.id);

            if (mealKit) {
                const index = state[neededState].indexOf(mealKit);
                state[neededState][index] = newMealKit;
            }
        },
    },
    actions: {
        async getAllMealKits({ commit }) {
            const response = await MealKitApi.getAll();

            const regularKits = response.data.filter((mealKit) => !mealKit.isUnique);
            const uniqueKits = response.data.filter((mealKit) => mealKit.isUnique);

            commit('setRegularKits', regularKits);
            commit('setUniqueKits', uniqueKits);
        },

        async getIndividualOrderSettings({ commit }) {
            const response = await IndividualOrderSettingsApi.getIndividualOrderSettings();

            commit('setIndividualOrderSettings', response.data);
        },

        async destroyMealKit({ commit }, payload) {
            await MealKitApi.destroy(payload.id);

            commit('deleteMealKit', payload);
        },

        async changeMealKitVisibility({ commit }, payload) {
            const response = await MealKitApi.changeVisibility(payload.id, payload.data);

            commit('updateMealKit', response.data);
        },

        async getAllMealKitsWithIO({ commit, state, dispatch }) {
            await dispatch('getAllMealKits');
            await dispatch('getIndividualOrderSettings');

            const payload = {
                regularKits: state.regularKits,
                uniqueKits: state.uniqueKits,
                individualOrderSettings: state.individualOrderSettings,
            };

            commit('setAllMealKitsWithIO', payload);
        },

        async getMealKit({ commit }, id) {
            const response = await MealKitApi.show(id);

            commit('setCurrentMealKit', response.data);
        },
    },
};
