import {
    checkIfCheckOutExist,
    individualDeliveryGuard,
    individualBuyerInfoGuard,
    submitCheckOutGuard,
} from '@/router/Guards/index.guard';

export default [
    {
        path: '/individual-order',
        component: () => import('@/layouts/IndividualOrderLayout'),
        name: 'individualOrderWrapper',
        redirect: { name: 'individualOrderKit' },
        children: [
            {
                path: '',
                component: () => import('@/pages/IndividualOrder/IndividualOrderPreferences.vue'),
                name: 'individualOrderKit',
            },
            {
                path: 'delivery',
                component: () => import('@/pages/IndividualOrder/Delivery.vue'),
                name: 'individualOrderDelivery',
                beforeEnter: individualDeliveryGuard,
            },
            {
                path: 'buyer-info',
                component: () => import('@/pages/IndividualOrder/BuyerInfo'),
                name: 'individualOrderBuyerInfo',
                beforeEnter: individualBuyerInfoGuard,
            },
            {
                path: 'submit',
                component: () => import('@/pages/IndividualOrder/IndividualOrderSubmit.vue'),
                name: 'individualOrderSubmit',
                beforeEnter: submitCheckOutGuard,
            },
            {
                path: 'payment/:check_out_id(\\d+)',
                component: () => import('@/pages/IndividualOrder/PaymentPage.vue'),
                name: 'individualOrderPayment',
                beforeEnter: checkIfCheckOutExist,
            },
        ],
    },
];
