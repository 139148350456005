import store from '@/store/index';

const checkIfCityExist = async (to, from, next) => {
    try {
        try {
            await store.dispatch('city/getCity', to.params.city_id);
        } catch (error) {
            console.error(error);
        }

        next();
    } catch (error) {
        console.log(error);
        next({ name: 'page-not-found' });
    }
};

export { checkIfCityExist };
