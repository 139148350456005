import authRouter from './auth.router';
import adminRouter from './admin.router';
import mealKitsRouter from './mealKits.router';
import blogArticlesRouter from './blogArticles.router';
import userRouter from './user.router';
import individualOrderRouter from './individualOrder.router';
import checkOutRouter from '@/router/checkOut.router';
import { createRouter, createWebHistory } from 'vue-router';
import { authRoutesGuard, adminAuthorizationGuard, authorizationGuard, checkIfPageWithSlugExist } from '@/router/Guards/index.guard';
import store from '@/store/index';
import { toast } from '@/helpers/ToastHelper';

const routes = [
    {
        path: '/verslo-pasiulymas',
        component: () => import('@/layouts/FullLayout.vue'),
        name: 'business',
        children: [
            {
                path: '',
                component: () => import('@/pages/OrderManagement/BusinessOffer.vue'),
                name: 'BusinessOffer',
            },
        ],
    },
    {
        path: '/',
        component: () => import('@/layouts/DefaultLayout.vue'),
        name: 'index',
        children: [
            {
                path: '/p/:slug',
                component: () => import('@/pages/Pages/PageShow.vue'),
                name: 'page.show',
                beforeEnter: checkIfPageWithSlugExist
            },
            {
                path: 'kuciu-rinkinys',
                component: () => import('@/pages/OrderManagement/CristmasEveForm.vue'),
                name: 'CristmasEveForm',
            },
            {
                path: '/',
                name: 'main',
                component: () => import('@/pages/Main/Main.vue'),
            },
            {
                path: 'krepselis',
                name: 'cart',
                component: () => import('@/pages/Cart/Cart.vue'),
            },
            {
                path: 'kainos',
                name: 'prices',
                component: () => import('@/pages/Prices/Prices.vue'),
            },
            {
                path: 'savaites-meniu',
                name: 'weeksMenu',
                component: () => import('@/pages/WeeksMenu/WeeksMenu.vue'),
            },
            {
                path: 'kontaktai',
                name: 'contacts',
                component: () => import('@/pages/Contacts/Contacts.vue'),
            },
            {
                path: 'es-parama',
                name: 'EUSupport',
                component: () => import('@/pages/EUSupport/EUSupport.vue'),
            },
            ...mealKitsRouter,
            ...checkOutRouter,
            ...individualOrderRouter,
            ...blogArticlesRouter,
            {
                path: 'duk',
                name: 'faq',
                component: () => import('@/pages/FAQ/FAQ.vue'),
            },
            {
                path: 'terms-and-conditions',
                name: 'termsAndConditions',
                component: () => import('@/pages/TermsAndConditions/TermsAndConditions.vue'),
            },
            {
                path: 'privacy-policy',
                name: 'privacyPolicy',
                component: () => import('@/pages/PrivacyPolicy/PrivacyPolicy.vue'),
            },
            {
                path: 'data-deletion-policy',
                name: 'dataDeletionPolicy',
                component: () => import('@/pages/DataDeletionPolicy/DataDeletionPolicy.vue'),
            },
            {
                path: '/:catchAll(.*)*',
                name: 'page-not-found',
                component: () => import('@/pages/NotFound/NotFound.vue'),
            },
        ],
    },
    {
        path: '/auth',
        component: () => import('@/layouts/AuthLayout.vue'),
        children: [...authRouter],
        beforeEnter: authRoutesGuard,
    },
    {
        path: '/admin',
        component: () => import('@/layouts/AdminLayout.vue'),
        name: 'adminRoutes',
        redirect: { name: 'adminDishes' },
        children: [...adminRouter],
        beforeEnter: adminAuthorizationGuard,
    },
    {
        path: '/user',
        component: () => import('@/layouts/AuthUserLayout'),
        name: 'userRoutes',
        redirect: { name: 'kitManagement' },
        children: [...userRouter],
        beforeEnter: authorizationGuard,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    linkActiveClass: 'active-link',
    scrollBehavior() {
        document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
    },
});

router.beforeEach(async (to) => {
    const user = store.getters['auth/user'];
    if (!user) {
        if(to.name != "login" && to.name != "register") { 
            console.log(`Save ${to.name}`)
            
            store.commit('auth/setReturnUrl', {
                name: to.name,
                params: to.params,
                query: to.query,
            });
        }

        try {
            await store.dispatch('auth/setAuthUser');
        } catch (error) {
            toast(error.message);
        }
    }
});

export default router;
