import { BlogArticleApi } from '@/api';

export const blogArticle = {
    namespaced: true,
    state: {
        blogArticles: [],
        currentBlog: null,
        meta: {
            pageCount: 1,
            currentPage: 1,
            totalCount: 0,
            perPage: 0,
        },
        paramsData: {
            page: 1,
        },
    },
    getters: {
        blogArticles: (state) => {
            return state.blogArticles;
        },
        meta: (state) => {
            return state.meta;
        },
        currentBlog: (state) => {
            return state.currentBlog;
        },
    },
    mutations: {
        setBlogArticles(state, blogArticles) {
            state.blogArticles = blogArticles;
        },

        setMeta(state, meta) {
            state.meta = meta;
        },

        setCurrentBlog(state, currentBlog) {
            state.currentBlog = currentBlog;
        },

        updateParamsField(state, payload) {
            Object.entries(payload).forEach((element) => {
                state.paramsData[element[0]] = element[1];

                if (element[0] !== 'page') {
                    state.paramsData.page = 1;
                }
            });
        },

        deleteBlogArticle(state, id) {
            const blogArticles = state.blogArticles.filter((kit) => kit.id !== id);
            state.blogArticles = [...blogArticles];
        },
    },
    actions: {
        async getAllBlogArticles({ commit, state }, payload = {}) {
            commit('updateParamsField', payload);

            const response = await BlogArticleApi.getAll(state.paramsData);

            const meta = {
                pageCount: response.meta.last_page,
                currentPage: response.meta.current_page,
                totalCount: response.meta.total,
                perPage: response.meta.to - response.meta.from + 1,
            };

            commit('setBlogArticles', response.data);
            commit('setMeta', meta);
        },

        async getBlogArticle({ commit }, id) {
            const response = await BlogArticleApi.show(id);

            commit('setCurrentBlog', response.data);
        },

        async destroyBlogArticle({ commit }, id) {
            await BlogArticleApi.destroy(id);

            commit('deleteBlogArticle', id);
        },
    },
};
