import Api from './api';

export class AddressApi {
    static store = async (data) => {
        const response = await Api.axios.post('addresses', data);
        return response.data;
    };

    static update = async (id, data) => {
        const response = await Api.axios.put(`addresses/${id}`, data);
        return response.data;
    };

    static destroy = async (id) => {
        const response = await Api.axios.delete(`addresses/${id}`);
        return response.data;
    };
}
