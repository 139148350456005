import Api from './api';

export class ProductApi {
    static getAll = async () => {
        const response = await Api.axios.get('products');
        return response.data;
    };

    static store = async (data) => {
        const response = await Api.axios.post('products', data);
        return response.data;
    };

    static update = async (data, id) => {
        const response = await Api.axios.put(`products/${id}`, data);
        return response.data;
    };

    static destroy = async (id) => {
        const response = await Api.axios.delete(`products/${id}`);
        return response.data;
    };
}
