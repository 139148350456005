import { useToast } from 'vue-toastification';
import { TYPE } from 'vue-toastification';

export const toast = (text, options) => {
    const toast = useToast();
    if(options !== undefined) {
        toast(text, {
            type: (options.type === undefined ? TYPE.ERROR : options.type),
            ...options,
        });
    } else {
        toast(text, {
            type: TYPE.ERROR,
            ...options,
        });
    }
};
