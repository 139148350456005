import axios from 'axios';

export class ConversionsApi {
    static sendFbEvent = async (data) => {
        if (api.env.APP_ENV === 'production') {
            const response = await axios.post(
                `https://graph.facebook.com/${api.env.FB_API_VERSION}/${api.env.FB_PIXEL_ID}/events?access_token=${api.env.FB_PIXEL_TOKEN}`,
                { data }
            );

            return response.data;
        }
    };
}
