import store from '@/store/index';

const checkIfBlogArticleExist = async (to, from, next) => {
    try {
        try {
            await store.dispatch('blogArticle/getBlogArticle', to.params.blog_article_id);
        } catch (error) {
            this.$filters.toast(error.message);
        }

        next();
    } catch (error) {
        next({ name: 'page-not-found' });
    }
};

export { checkIfBlogArticleExist };
