class LocalStorage {
    static show(key) {
        return JSON.parse(localStorage.getItem(key));
    }

    static create(key, params) {
        localStorage.setItem(key, JSON.stringify(params));
    }

    static delete(key) {
        localStorage.removeItem(key);
    }
}

export default LocalStorage;
