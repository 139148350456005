import LocalStorage from '@/helpers/LocalStorageHelper';
import Cookies from 'js-cookie';
import { PaymentTypeEnum } from '@/utils/enums';

export const checkOut = {
    namespaced: true,
    state: {
        isContinueCheckout: false,
        checkOut: null,
        paymentType: PaymentTypeEnum.MONTONIO,
        paymentData: {
            provider: null,
            payment: null,
            country: null,
        }
    },
    getters: {
        isContinueCheckout(state) {
            return state.isContinueCheckout;
        },
        checkOut: (state) => {
            return state.checkOut;
        },
        paymentType: (state) => {
            return state.paymentType;
        },
        paymentData: (state) => {
            return state.paymentData;
        }
    },
    mutations: {
        setContinueCheckout(state, isContinueCheckout) {
            state.isContinueCheckout = isContinueCheckout;
        },
        setCheckOut(state, checkOut) {
            state.checkOut = checkOut;
        },

        setPaymentType(state, paymentType) {
            state.paymentType = paymentType;
        },

        setPaymentData(state, paymentData) {
            state.paymentData = paymentData;
        },
    },
    actions: {
        setContinueCheckout({ commit}, isContinueCheckout) {
            commit('setContinueCheckout', isContinueCheckout)
        },
        setPaymentData({ commit }, paymentData) {
            commit('setPaymentData', {
                provider: paymentData.provider || null,
                payment: paymentData.payment || null,
                country: paymentData.country || null,
            });
        },
        setPaymentType({ commit }, paymentType) {
            const paymentTypeFromCookie = Cookies.get('paymentType');

            if (paymentTypeFromCookie || paymentType) {
                commit('setPaymentType', paymentType ?? paymentTypeFromCookie);
                Cookies.set('paymentType', paymentType ?? paymentTypeFromCookie, { expires: 1 / 24 });
            }
        },

        setCheckOut({ commit }, checkOut) {
            const checkOutFromLocalStorage = LocalStorage.show('checkOut');

            if (typeof checkOut === 'undefined') {
                if (checkOutFromLocalStorage) {
                    commit('setCheckOut', checkOutFromLocalStorage);
                    LocalStorage.create('checkOut', checkOutFromLocalStorage);
                }
            } else {
                LocalStorage.create('checkOut', checkOut);
                commit('setCheckOut', checkOut);
            }
        },

        clearCheckOutsCookies({ commit, dispatch }) {
            Cookies.remove('delivery-information');
            Cookies.remove('buyer-information');
            LocalStorage.delete('checkOut');

            dispatch('removePaymentTypeCookie');

            commit('orderInformation/setDeliveryInformation', null, { root: true });
            commit('orderInformation/setBuyerInformation', null, { root: true });
            commit('orderPrices/setPrices', null, { root: true });
            commit('setCheckOut', null);
        },

        removePaymentTypeCookie({ commit }) {
            Cookies.remove('paymentType');

            commit('setPaymentType', PaymentTypeEnum.MONTONIO);
        },
    },
};
