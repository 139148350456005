import Api from './api';

export class PeriodApi {
    static getAll = async () => {
        const response = await Api.axios.get('periods');
        return response.data;
    };

    static store = async (data) => {
        const response = await Api.axios.post('periods', data);
        return response.data;
    };

    static destroy = async (id) => {
        const response = await Api.axios.delete(`periods/${id}`);
        return response.data;
    };
}
