import Api from './api';

export class EvaluateDishSettingApi {
    static get = async () => {
        const response = await Api.axios.get('evaluate-dish-settings');
        return response.data;
    };

    static update = async (id, data) => {
        const response = await Api.axios.put(`evaluate-dish-settings/${id}`, data);
        return response.data;
    };
}
