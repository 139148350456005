import Api from './api';

export class ForgotPasswordApi {
    static forgot = async (data) => {
        const response = await Api.axios.post('password/forgot', data);
        return response.data;
    };

    static reset = async (data) => {
        const response = await Api.axios.post('password/reset', data);
        return response.data;
    };
}
