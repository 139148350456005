export const IndividualOrderStatusEnum = {
    PENDING: 'pending',
    APPROVED: 'approved',
    CANCELLATION_REQUEST: 'cancellation_request',
    DECLINED: 'declined',
    PAID: 'paid',
    NOT_PAID: 'not_paid',
    COMPLETED: 'completed',
    STOPPED: 'stopped',
};
