import Api from './api';

export class OrderApi {
    static getAll = async (params = {}) => {
        const response = await Api.axios.get('orders', { params });
        return response.data;
    };

    static store = async (data) => {
        const response = await Api.axios.post('orders', data);
        return response.data;
    };

    static update = async (id, data) => {
        const response = await Api.axios.put(`orders/${id}`, data);
        return response.data;
    };

    static getOrderCancellationPrice = async (id) => {
        const response = await Api.axios.get(`orders/${id}/cancellation-price`);
        return response.data;
    };

    static delete = async (id) => {
        const response = await Api.axios.delete(`orders/${id}`);
        return response.data;
    };

    static recover = async (id) => {
        const response = await Api.axios.post(`orders/${id}`);
        return response.data;
    };
}
