import Api from './api';

export class PictureApi {
    static store = async (data) => {
        const response = await Api.axios.post('pictures', data, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
        return response.data;
    };

    static makeThumbnail = async (id) => {
        const response = await Api.axios.post(`pictures/${id}/make-thumbnail`);
        return response.data;
    };

    static destroy = async (id) => {
        const response = await Api.axios.delete(`pictures/${id}`);
        return response.data;
    };
}
